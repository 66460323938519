import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { getAxiosConfig, useProfile } from 'utils/index';
import LoaderCardUser from 'components/loaders/user-card';
import PanelTop from 'components/layout/panel-top';
import Input from 'components/forms/input';
import ButtonLink from 'components/misc/button-link';
import Button from 'components/misc/button';
import CardPatient from 'components/cards/patient';

const Patients = () => {
  const { t } = useTranslation('__global');
  const [hideInactiveUsers, updateHideInactive] = useState(
    localStorage.getItem('hide_inactive') === 'true' ? true : false,
  );
  const [patientList, setPatientList] = useState({
    data: null,
    loading: true,
  });
  const profile = useProfile((data) => {
    if (data.role === 'role.hcp') {
      updateTScope('patients');
    }
  });
  const [searchValue, updateSearchValue] = useState('');
  const hideInactive = () => {
    setPatientList({ data: patientList.data, loading: true });
    localStorage.setItem('hide_inactive', !hideInactiveUsers);
    updateHideInactive(!hideInactiveUsers);
  };
  const [tScope, updateTScope] = useState('users');

  useEffect(() => {
    const fetchData = async () => {
      let query_objects = {};
      let filters = [];
      if (hideInactiveUsers) {
        filters.push('hideInactive');
      }
      filters = filters.join('|');
      if (filters) {
        query_objects.filters = filters;
      }

      if (searchValue) {
        query_objects.search = searchValue;
      }

      var esc = encodeURIComponent;
      var query = Object.keys(query_objects)
        .map((k) => esc(k) + '=' + esc(query_objects[k]))
        .join('&');

      const result = await axios.get(
        process.env.REACT_APP_API_URL + 'users' + (query ? '?' + query : ''),
        getAxiosConfig(),
      );
      if (result.data.status !== 'E_PERMISSION_MISSING') {
        setPatientList({
          data: result.data,
          loading: false,
        });
      }
    };

    fetchData();
  }, [hideInactiveUsers, searchValue]);

  const handleChange = (event) => {
    updateSearchValue(event.target.value);
  };

  var placeholders = [];
  for (var i = 0; i < 12; i++) {
    placeholders.push(<LoaderCardUser key={i} />);
  }

  return (
    <div className="view">
      <PanelTop
        loading={patientList.loading || profile.loading}
        left={
          <div className="col-s-1 col-e-5">
            <Input
              name="search"
              icon="search"
              value={searchValue}
              infield="true"
              label={t('base.search')}
              handleChange={handleChange}
            />
          </div>
        }
        right={
          <>
            <ButtonLink
              icon={hideInactiveUsers ? 'eye' : 'eye-off'}
              label={
                hideInactiveUsers
                  ? t(tScope + '.show_inactive')
                  : t(tScope + '.hide_inactive')
              }
              loading={patientList.loading}
              action={hideInactive}
            />
            <Button
              label={t(tScope + '.create')}
              icon="arrow-right"
              link="/users/new"
              type="prominent"
            />
          </>
        }
      />
      <div className="grid content">
        {patientList.loading || profile.loading ? (
          <>{placeholders}</>
        ) : patientList.data.Users === null ? (
          <strong>no patients</strong>
        ) : (
          patientList.data.map((user) => (
            <CardPatient key={user.id} profile={user} />
          ))
        )}
      </div>
    </div>
  );
};

export default Patients;
