import Button from 'components/misc/button';
import React from 'react';
import { useTranslation } from 'react-i18next';

import '../../theme/components/card-doctor.scss';

const CardDoctor = () => {
  const { t } = useTranslation('__global');

  return (
    <div className="card doctor-card flex splitted">
      <div className="info">
        <h4>Dr. med. Adrian Casty</h4>
        <p>Facharzt Medizinische Onkologie</p>

        <p>
          +41 31 302 02 02
          <br />
          adrian.casty@somewhere.ch
        </p>
      </div>
      <div className="actions">
        <Button label={t('base.call')} icon="phone" />
        <Button label={t('base.write_email')} icon="send" />
      </div>
    </div>
  );
};

export default CardDoctor;
