import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import base64 from 'base-64';
import { formatDate, getAxiosConfig, padNum, useProfile } from 'utils/index';
import TableZapper from 'components/table/table-zapper';
import PanelTop from 'components/layout/panel-top';
import Table from 'components/table/table';
import TableRow from 'components/table/table-row';
import LoadingIndicator from 'components/loaders/indicator';

const Logbook = () => {
  const { t } = useTranslation('__global');
  const profile = useProfile();
  const [logBook, setLogBook] = useState(false);
  const [total, setTotalInjections] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const injectionsPerPage = 15;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const query =
        process.env.REACT_APP_API_URL +
        'injection/get/user/' +
        profile.data.id +
        '?deep=false&limit=' +
        injectionsPerPage +
        '&page=' +
        page;
      const result = await axios.get(query, getAxiosConfig());
      const counter = await axios.get(query + '&count=true', getAxiosConfig());
      if (result.data.length > 0) {
        setTotalInjections(counter.data.injection_total);
        setLogBook(result.data);
        setLoading(false);
      }
    };

    if (!profile.loading) {
      fetchData();
    }
  }, [profile, page]);

  const getTooltip = (data) => {
    if (!data) {
      return;
    }
    let tip = '';
    try {
      tip += base64.decode(data);
      tip = tip.replace(/(?:\r\n|\r|\n)/g, '<br />');
    } catch (e) {
      console.error(e);
    }

    try {
      tip += JSON.stringify(data, null, 2);
      tip = tip.replace(/(?:\r\n|\r|\n)/g, '<br />');
    } catch (e) {
      console.error(e);
    }

    return tip;
  };

  const zapper = (
    <TableZapper
      total={total}
      page={page}
      pagesize={injectionsPerPage}
      directAction={(target) => {
        setPage(target);
      }}
      nextAction={() => {
        var newPage = parseInt(page, 10) + 1;
        setPage(newPage);
      }}
      prevAction={() => {
        var newPage = parseInt(page, 10) - 1;
        setPage(newPage);
      }}
    />
  );

  return (
    <div className="view">
      <PanelTop
        loading={profile.loading}
        left={<h1>{t('logbook.title')}</h1>}
      />
      <div className="content">
        {logBook ? (
          <>
            <Table
              loading={loading}
              before={zapper}
              head={
                <TableRow
                  type="th"
                  data={[
                    t('logbook.table.id'),
                    t('logbook.table.date'),
                    t('logbook.table.device'),
                    t('logbook.table.data'),
                  ]}
                />
              }
              body={logBook.map((row) => (
                <TableRow
                  key={row.id}
                  data={[
                    padNum(row.id, 0, 4),
                    formatDate(row.date),
                    row.device.name,
                    {
                      value:
                        typeof row.data === String
                          ? row.data.substring(0, 30) +
                            (row.data.length > 29 ? '...' : '')
                          : t('logbook.table.see_data'),
                      tooltip: getTooltip(row.data),
                    },
                  ]}
                />
              ))}
              footer={zapper}
            />
          </>
        ) : (
          <LoadingIndicator />
        )}
      </div>
    </div>
  );
};
export default Logbook;
