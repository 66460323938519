import PanelTop from 'components/layout/panel-top';
import Button from 'components/misc/button';
import ButtonBack from 'components/misc/button-back';
import Modal from 'components/misc/modal';
import DeviceFactsListing from 'components/organisms/device-facts-listing';
import useDevice from 'hooks/device';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';

import '../../theme/views/device.scss';

const DeviceDetail = (props) => {
  const { t } = useTranslation('__global');
  const deviceId = props.computedMatch.params.id;
  const device = useDevice(deviceId);
  const [duplicateCheck, setDuplicateCheck] = useState(false);
  const [showQR, setShowQR] = useState(false);

  const toggleQRModal = () => {
    setShowQR(!showQR);
  };

  useEffect(() => {
    if (device.injection && device.injection[0].validation == 'CHALLENGE') {
      setDuplicateCheck(true);
    }
  }, [device]);

  return (
    <div className="view device-detail">
      <Modal
        show={showQR}
        content={
          <div className="content">
            {device.external_device_id ? (
              <QRCode size={256} value={device.external_device_id} />
            ) : (
              <></>
            )}
          </div>
        }
        onHide={toggleQRModal}
      />
      <PanelTop
        left={
          <>
            <ButtonBack to="/devices" />
            <h1>{device.type}</h1>
          </>
        }
        right={
          <>
            {duplicateCheck ? (
              <Button
                label={t('devicefacts.showQr')}
                icon="grid"
                action={toggleQRModal}
              />
            ) : (
              <></>
            )}
          </>
        }
      />
      <DeviceFactsListing deviceId={deviceId} />
    </div>
  );
};
export default DeviceDetail;
