import React from 'react';

import '../../theme/components/panel-top.scss';

const PanelTop = (props) => {
  let classes = 'panel-top';
  if (props.navigation) {
    classes += ' v-stack left';
  }
  return (
    <div className={classes}>
      {props.loading ? (
        <></>
      ) : (
        <div className="l-r">
          <div className="left flex">{props.left}</div>
          <div className="right">{props.right}</div>
        </div>
      )}
      {props.navigation ? props.navigation : <></>}
    </div>
  );
};

export default PanelTop;
