import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { getAxiosConfig } from 'utils/index';
import PanelTop from 'components/layout/panel-top';
import ButtonBack from 'components/misc/button-back';
import ButtonLink from 'components/misc/button-link';
import DefinitionListEntry from 'components/misc/definition-list-entry';

const DevicesDetail = (props) => {
  const { t } = useTranslation('__global');
  const [deviceInfo, setDeviceInfo] = useState(false);
  const deviceId = props.computedMatch.params.deviceId;
  const [playDemoLoading, setDemoLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(
        process.env.REACT_APP_API_URL + 'devices/get/' + deviceId,
        getAxiosConfig(),
      );
      result.data.remote_data = JSON.parse(result.data.remote_data);
      setDeviceInfo(result.data);
    };
    fetchData();
  }, []);

  const sendDemoCommand = async () => {
    setDemoLoading(true);
    const result = await axios.post(
      process.env.REACT_APP_API_URL +
        'devices/play_demo/' +
        deviceInfo.external_device_id,
      {},
      getAxiosConfig(),
    );
    console.log(result);
    setDemoLoading(false);
  };

  return (
    <div className="view">
      <PanelTop
        loading={!deviceInfo ? true : false}
        left={
          <>
            <ButtonBack />
            <h1>{t('devices.types.' + deviceInfo.type)}</h1>
          </>
        }
        right={
          <ButtonLink
            icon="bell"
            label={t('devices.play_demo')}
            loading={playDemoLoading}
            action={sendDemoCommand}
          />
        }
      />
      <div className="content">
        {deviceInfo ? (
          <div className="device-info">
            <h3>{t('base.technical_information')}</h3>
            <dl className="v-stack">
              <DefinitionListEntry
                label={t('devicefacts.id')}
                value={deviceInfo.external_device_id}
              />
              <DefinitionListEntry
                label={t('devicefacts.type')}
                value={deviceInfo.type}
                display={deviceInfo.type != undefined}
              />
            </dl>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default DevicesDetail;
