import ButtonLink from 'components/misc/button-link';
import Icons from 'components/misc/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils';

const CardNotification = ({ data, readClick, moreClick }) => {
  const { t } = useTranslation('__global');

  const icons = ['info', 'alert-triangle', 'alert-octagon warning'];
  var warning = 'level-icon';
  if (data.level == 2) {
    warning = 'level-icon warning';
  }
  if (data.level == 3) {
    warning = 'level-icon error';
  }
  var icon = icons[data.level - 1];

  const date = formatDate(data.created);

  return (
    <div className="notification-card">
      {data.read == null ? (
        <div className="red-dot">
          <div className="red-dot-outer"></div>
          <div className="red-dot-inner"></div>
        </div>
      ) : (
        ''
      )}
      <div className="col-e-end grid">
        <div className="col-s-1 col-e-9 flex">
          <div className="v-stack device-data">
            <small className="strong">{date}</small>
            <h4 className="no-margin">{data.title}</h4>
            <p className="no-margin">{data.text}</p>
          </div>
        </div>
        <div className="flex-end align-center col-s-9 col-e-13 flex-space">
          {data.link != null ? (
            <ButtonLink
              icon={null}
              label={t('notification.readmore')}
              action={moreClick}
              data_index={data.link}
              className=" button defautlt button-discreet"
            />
          ) : (
            ''
          )}
          {data.read == null && data.level === 3 ? (
            <ButtonLink
              icon={null}
              label={t('notification.markread')}
              action={readClick}
              data_index={data.id}
              className="button defautlt button-discreet"
            />
          ) : (
            ''
          )}
          <div className={warning}>
            <Icons show={icon} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardNotification;
