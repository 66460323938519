import React, { useEffect, useState } from 'react';

import '../../theme/components/modal.scss';
import Icons from './icons';
import Button from './button';

/**
 * Modal component with animations
 * Don't use fadeIn and moveIn at the same time, it will result in a laggy animation
 * @param show
 * @param content
 * @param fadeIn make the modal fade in
 * @param moveIn make the modal move in
 * @param onHide
 * @param displaySaveButton
 * @param onSave
 * @returns {JSX.Element}
 * @constructor
 */
const Modal = ({
  show = false,
  content = 'content',
  fadeIn = false,
  moveIn = false,
  onHide = () => {},
  displaySaveButton = false,
  onSave = () => {},
}) => {
  const [showModal, setShowModal] = useState(show);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const hideModal = () => {
    setShowModal(false);
    onHide();
  };

  return (
    <div
      className={`modal-container ${showModal ? 'show' : ''} ${
        fadeIn && showModal ? 'fade-in' : ''
      }`}>
      <div className="modal-cover" onClick={hideModal}></div>
      <div className={`modal ${moveIn ? 'move-in' : ''}`}>
        <a className="close no-decoration" onClick={hideModal}>
          <Icons show="x" />
        </a>
        <div className="content">{content}</div>
        {displaySaveButton && (
          <div className="save-button-wrapper">
            <Button label="Save & Close" icon={'arrow-right'} action={onSave} />
          </div>
        )}
      </div>
    </div>
  );
};
export default Modal;
