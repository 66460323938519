import React from 'react';
import ReactDOM from 'react-dom';
import '../../theme/components/services.scss';
import StandaloneServiceList from '../misc/standalone-service-list';
import EnvironmentList from '../misc/environment-list';

const Services = () => {
  // Make ReactDom not unused, because it is used by the ReactTooltip component.
  // If we didn't do this, the website would not build.
  ReactDOM.version;

  return (
    <div className="services-card card">
      <div className="card-header">
        <h3>Services & Environments</h3>
      </div>
      <div className="splitline" />
      <div className="service-env-list">
        <StandaloneServiceList />
        <EnvironmentList />
      </div>
    </div>
  );
};

export default Services;
