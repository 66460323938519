import PrivateRoute from 'components/routes/private';
import PublicRoute from 'components/routes/public';
import React from 'react';
import { Switch } from 'react-router-dom';
import Devices from 'views/patient/devices';
import DevicesDetail from 'views/patient/devices-detail';
import Logbook from 'views/patient/logbook';
import ReadQR from 'views/patient/read-qr';
import RoutesGeneral from './general';

const RoutesPatient = () => {
  return (
    <Switch>
      <PublicRoute restricted={false} component={ReadQR} path="/qr" exact />
      <PrivateRoute component={Devices} path="/devices" exact />
      <PrivateRoute component={Logbook} path="/logbook" exact />
      <PrivateRoute component={DevicesDetail} path="/devices/:deviceId" exact />

      <RoutesGeneral />
    </Switch>
  );
};
export default RoutesPatient;
