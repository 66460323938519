import { useEffect, useState } from 'react';
import axios from 'axios';
import { getAxiosConfig } from 'utils/index';

const useDevice = (deviceId) => {
  const [device, setDevice] = useState({ loading: true });

  useEffect(() => {
    const fetchDevice = async () => {
      let devices = await axios.get(
        process.env.REACT_APP_API_URL + 'devices/get/' + deviceId,
        getAxiosConfig(),
      );
      const result = { ...devices.data };
      result.loading = false;
      setDevice(result);
    };
    fetchDevice();
  }, [deviceId]);

  return device;
};
export default useDevice;
