import Input from 'components/forms/input';
import Select from 'components/forms/select';
import PanelTop from 'components/layout/panel-top';
import Button from 'components/misc/button';
import ToolsNavigation from 'components/navigations/detail/tools';
import protobuf from 'protobufjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Buffer } from 'buffer';
import fileConfig from './protobufConfig.json';

const ToolsProtobufWriter = () => {
  const { t: tGlobal } = useTranslation('__global');
  const { t: tYpsomed } = useTranslation('__ypsomed');
  const [dataFields, setDataFields] = useState([]);
  const [resultDisplay, setResultDisplay] = useState('');
  const [resultData, setResultData] = useState('');
  const [proto, setProto] = useState('');
  const [formData, setFormData] = useState({
    definition: '',
  });
  const [protoData, setProtoData] = useState([]);
  const [triggerFieldUpdate, setTriggerFieldUpdate] = useState(0);

  const createProto = () => {
    // check if we have a formData and a proto string
    if (formData.definition === '' || proto === '') {
      setDataFields([]);
      return;
    }

    // Parse the data string
    const root = protobuf.parse(proto, { keepCase: true }).root;

    const configObject = fileConfig.files.find(
      (item) => item.value === formData.definition,
    );

    // Get the message type
    const dynamicMessage = root.lookupType(configObject.root);

    // Get all the fields of the message type
    const fieldsArray = dynamicMessage.fieldsArray;

    // loop through fields and set values
    const message = {};
    fieldsArray.forEach((field) => {
      const index = dataFields.findIndex((item) => item.name === field.name);
      if (index !== -1) {
        // check field type and convert value
        const booleanFields = ['isCapRemoved'];

        // check if the field is mandatory
        const isMandatory = field.rule === 'proto3.FieldRule.REQUIRED';

        // define fields which will be parsed as int
        const intFields = [
          'uint64',
          'uint32',
          'int32',
          'DataId',
          'Cipher',
          'LiPoState',
          'SystemState',
          'sequenceNumber',
          'timestamp',
          'actualWarmupDuration',
          'actualInjectionDuration',
          'actualHoldingDuration',
          'InjectionState',
          'DrugCheckState',
          'ConnectionState',
        ];

        // define fields which will be base64 encoded
        const base64Fields = [
          'deviceId',
          'payload',
          'mac',
          'initVector',
          'firmwareVersion',
          'settingsVersion',
        ];

        const otherMessage = [
          'systemStateEv',
          'drugCheckStateEv',
          'lipoStateEv',
          'connectionStateEv',
          'injectionStateEv',
          'commandRequestEv',
          'systemErrorEv',
        ];

        const otherMessagesArray = ['events'];

        // field is not mandatory and protoData[index] is empty or undefined
        // just continue with next field
        if (
          (!isMandatory && protoData[index] === '') ||
          protoData[index] === undefined
        ) {
          return;
        }

        if (intFields.includes(field.type)) {
          message[field.name] = parseInt(protoData[index], 10);
        } else if (base64Fields.includes(field.name)) {
          message[field.name] = Buffer.from(protoData[index], 'utf-8').toString(
            'base64',
          );
        } else if (otherMessage.includes(field.name)) {
          // parse base64 encoded string to protobuf message object
          const buffer = Buffer.from(protoData[index], 'base64');
          const fieldMessage = root.lookupType(field.type);
          const decodedMessage = fieldMessage.decode(buffer);
          const decodedMessageObject = fieldMessage.toObject(decodedMessage);
          message[field.name] = decodedMessageObject;
        } else if (otherMessagesArray.includes(field.name)) {
          // split message by comma and parse each message
          const messages = protoData[index].split(',');
          const messageArray = [];
          const fieldMessage = root.lookupType(field.type);
          messages.forEach((messageString) => {
            console.log(field.type);
            // parse base64 encoded string to protobuf create message object
            const buffer = Buffer.from(messageString, 'base64');
            const decodedMessage = fieldMessage.decode(buffer);
            const decodedMessageObject = fieldMessage.toObject(decodedMessage);
            messageArray.push(decodedMessageObject);
          });
          message[field.name] = messageArray;
        } else if (booleanFields.includes(field.name)) {
          message[field.name] = protoData[index] === 'true';
        } else {
          message[field.name] = protoData[index];
        }
      }
    });

    // Create a new message
    const errMsg = dynamicMessage.verify(message);
    if (errMsg) {
      console.error(errMsg);
      return;
    }
    const dynamicMessageInstance = dynamicMessage.create(message);
    const buffer = dynamicMessage.encode(dynamicMessageInstance).finish();

    const base64String = Buffer.from(buffer).toString('base64');
    setResultData(base64String);
    setResultDisplay(<pre>{base64String}</pre>);
  };

  const handleSelectChange = (event) => {
    setFormData({
      ...formData,
      definition: event.target.value,
    });
  };

  const handleDownload = () => {
    // get unix timestamp
    const timestamp = Math.floor(Date.now() / 1000);
    const filename = formData.definition + '-' + timestamp + '.bin';
    const downloadLink = document.createElement('a');
    downloadLink.setAttribute(
      'href',
      `data:application/octet-stream;base64,${resultData}`,
    );
    downloadLink.setAttribute('download', filename);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  useEffect(() => {
    // check if select has a value
    if (formData.definition === '') {
      setProto('');
      return;
    }

    // load the .proto file from the select value
    const url = '/protobuf/' + formData.definition;
    fetch(url)
      .then((response) => response.text())
      .then((text) => {
        setProto(text);
        setTriggerFieldUpdate(triggerFieldUpdate + 1);
      });
  }, [formData]);

  useEffect(() => {
    // check if we have a formData and a proto string
    if (formData.definition === '' || proto === '') {
      setDataFields([]);
      return;
    }

    // Parse the data string
    const root = protobuf.parse(proto, { keepCase: true }).root;

    const configObject = fileConfig.files.find(
      (item) => item.value === formData.definition,
    );

    // Get the message type
    console.log(configObject);
    const dynamicMessage = root.lookupType(configObject.root);

    // Get all the fields of the message type
    const fields = dynamicMessage.fieldsArray;

    // save the fields
    setDataFields(Object.keys(fields).map((key) => fields[key]));
  }, [triggerFieldUpdate]);

  return (
    <div className="view tools">
      <PanelTop
        left={<h1>{`${tGlobal('base.tools')}`}</h1>}
        navigation={<ToolsNavigation active="protobuf-writer" />}
      />
      <div className="area inner-content">
        <h2>{`${tYpsomed('tools.pbWriter.title')}`}</h2>
        <p>{`${tYpsomed('tools.pbWriter.intro')}`}</p>
        <div className="form">
          <Select
            label={tYpsomed('tools.pbReader.reader_select_proto')}
            values={fileConfig.files}
            handleChange={handleSelectChange}
          />
        </div>
        {dataFields.length > 0 && (
          <div className="form">
            <hr />
            <h3>{`${tYpsomed('tools.pbWriter.create_title')}`}</h3>
            {dataFields.map((field, index) => (
              <div key={field.name}>
                <Input
                  name={field.name}
                  label={`${field.name} (${field.type})`}
                  value={protoData[index]}
                  handleChange={(event) => {
                    const newInputFields = [...protoData];
                    newInputFields[index] = event.target.value;
                    setProtoData(newInputFields);
                  }}
                />
              </div>
            ))}
            <div className="flex v-center">
              <Button
                icon="cpu"
                type="prominent"
                label={tYpsomed('tools.pbWriter.create_file')}
                action={createProto}
              />
              {resultDisplay !== '' && (
                <Button
                  icon="download-cloud"
                  type="prominent"
                  label={tYpsomed('tools.pbWriter.download_file')}
                  action={handleDownload}
                />
              )}
            </div>
            <div className="mt-big"></div>
          </div>
        )}
        <div className="result mt-big">{resultDisplay}</div>
        <div className="mt-big"></div>
      </div>
    </div>
  );
};
export default ToolsProtobufWriter;
