import React from 'react';
import ContentLoader from 'react-content-loader';

const LoaderCardUser = (props) => (
  <div className="card w100">
    <ContentLoader
      speed={2}
      width={476}
      height={40}
      viewBox="0 0 476 40"
      backgroundColor="#d7d7d7"
      foregroundColor="#ecebeb"
      {...props}>
      <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
      <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
      <rect x="255" y="16" rx="3" ry="3" width="178" height="6" />
      <circle cx="20" cy="20" r="20" />
    </ContentLoader>
  </div>
);

export default LoaderCardUser;
