import Error from 'components/misc/error';
import Icons from 'components/misc/icons';
import React from 'react';

const Select = ({
  label = 'Undefined <label>',
  name = 'no-name-set',
  startingValue = '',
  handleChange = () => {},
  error = false,
  values = [],
}) => {
  const setFocus = (e) => {
    let container = e.target.closest('.form-element');
    container.classList.add('focus');
  };

  const setBlur = (e) => {
    let container = e.target.closest('.form-element');
    if (e.target.value !== '') return;
    container.classList.remove('focus');
  };

  let hasError = error ? 'has-error' : '';
  let clazzes = `form-element ${hasError} select`;

  return (
    <div className={clazzes}>
      {label ? <label>{label}</label> : <></>}
      <select
        id={`id-${name}`}
        name={name}
        onChange={handleChange}
        onFocus={setFocus}
        onBlur={setBlur}
        defaultValue={startingValue}>
        {values.map((value, index) => (
          <option key={index} value={value.value}>
            {value.label}
          </option>
        ))}
      </select>
      <Icons show="chevron-down" />
      {hasError ? <Error message={error} /> : ''}
    </div>
  );
};
export default Select;
