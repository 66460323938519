import LoadingIndicator from 'components/loaders/indicator';
import React from 'react';
import '../../theme/components/table.scss';

const Table = ({
  head = false,
  body = false,
  footer = false,
  loading = false,
  before = false,
}) => {
  let containerClazz = 'table-container';
  if (loading) {
    containerClazz += ' loading';
  }
  return (
    <div className={containerClazz}>
      {loading ? <LoadingIndicator /> : null}
      {before ? before : null}
      <table className="default">
        {head ? <thead>{head}</thead> : null}
        {body ? <tbody>{body}</tbody> : null}
      </table>
      {footer ? <div className="table-footer">{footer}</div> : null}
    </div>
  );
};

export default Table;
