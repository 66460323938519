import PanelTop from 'components/layout/panel-top';
import useDrugs from 'hooks/drugs';
import CardInjectionPharma from 'components/cards/injection-pharma';
import React, { useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import '../../theme/views/dashboard-pharma.scss';
import useInjections from 'hooks/injections';
import useDevices from 'hooks/devices';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import Icons from 'components/misc/icons';
import Button from 'components/misc/button';

const DashboardPharma = ({ profile }) => {
  if (profile.loading) {
    return <></>;
  }
  const { t } = useTranslation(['__global', '__urls']);
  const drugs = useDrugs({ pharma_id: profile.data.id });
  const devicesData = useDevices();
  const [fakeDevices, setFakeDevices] = useState(0);
  const [realDevices, setRealDevices] = useState(0);
  const [availableDevices, setAvailableDevices] = useState(0);
  const injections = useInjections({ pharma_id: profile.data.id, limit: 4 });

  useEffect(() => {
    const devices = devicesData.items;
    var __fakeDevices = 0;
    var __availableDevices = 0;

    for (var i = 0; i < devices.length; i++) {
      var device = devices[i];

      if (
        device.yds_provisioning_json_data == 'null' ||
        device.yds_provisioning_json_data == null
      ) {
        __fakeDevices++;
      } else {
        // is real device
        if (device.injection === null) {
          __availableDevices++;
        }
      }
    }

    setFakeDevices(__fakeDevices);
    setAvailableDevices(__availableDevices);
    setRealDevices(devices.length - __fakeDevices);
  }, [devicesData]);

  return (
    <div className="view">
      <PanelTop
        left={
          <>
            <small className="uppercase special">
              {t('dashboards.default.subtitle')}
            </small>
            <h1>
              <Trans
                t={t}
                i18nKey="dashboards.default.title"
                values={{
                  user: `${profile.data.forename} ${
                    profile.data.lastname
                      ? profile.data.forename
                        ? profile.data.lastname
                        : ''
                      : ''
                  }`,
                }}
              />
            </h1>
          </>
        }
      />
      <div className="content grid">
        <div className="col-s-1 col-e-9">
          <p>{t('dashboards.pharma.description')}</p>
        </div>
      </div>

      <div className="content grid mt-medium">
        <div className="col-s-1 col-e-8">
          <div className="grid">
            <div className="col-s-1 col-e-6">
              <h3 className="mt-0">{t('dashboards.pharma.injection_title')}</h3>
            </div>
            <div className="col-s-6 col-e-13">
              <Link
                to="/devices"
                className="button defautlt button-discreet float-right">
                <span className="text pl-0">
                  {t('dashboards.pharma.devices_link_label')}
                </span>
              </Link>
            </div>
          </div>

          <ul>
            {injections.map((item) => (
              <li key={item.id} className="mt-medium">
                <CardInjectionPharma data={item}></CardInjectionPharma>
              </li>
            ))}
          </ul>
        </div>
        <div className="col-s-9 col-e-13">
          <h3 className="mt-0 min-height-200x">
            {t('dashboards.pharma.yourdemodevices')}
          </h3>
          <div className="card mt-medium">
            <div className="grid">
              <div className="col-s-1 col-e-7">
                <h4 className="mt-0">{t('dashboards.pharma.ymon_title')}</h4>
                <div className="text-regular c-gray-300 line-height-medium">
                  {t('dashboards.pharma.ymon_desc')}
                </div>
                <Button
                  action={() =>
                    (window.location = t('ordermore', { ns: '__urls' }))
                  }
                  type="discreet"
                  label={t('dashboards.pharma.ordermore_label')}
                />
              </div>
              <div className="col-s-8 col-e-13">
                <div className="text-regular c-gray-300 mt-0">
                  {t('dashboards.pharma.ymon_avail_total')}
                </div>
                <h2 className="mt-0">{`${availableDevices}/${realDevices}`}</h2>
                <br></br>
                <div className="text-regular c-gray-300 mt-base">
                  {t('dashboards.pharma.fake_devices_title')}
                </div>
                <h2 className="mt-0">{fakeDevices}</h2>
              </div>
            </div>
          </div>
          <div className="drugs card mt-medium">
            <h3 className="mt-0">{t('dashboards.pharma.drug_widget.title')}</h3>
            <p className="mt-0">
              {t('dashboards.pharma.drug_widget.description')}
            </p>
            <ul>
              {drugs.map((value) => (
                <li key={value.id}>
                  {value.identifier} - {value.name}
                </li>
              ))}
            </ul>
          </div>
          <div className="links mt-medium">
            <ul>
              <li className="display-none">
                <Link
                  className="external block"
                  to={{ pathname: t('servicedesk', { ns: '__urls' }) }}
                  target="_blank">
                  <span className="text pl-0">
                    {t('dashboards.pharma.servicedesk_label')}
                  </span>
                  <Icons show="arrow-right"></Icons>
                </Link>
              </li>
              <li>
                <Link
                  className="external block"
                  to={{ pathname: t('productdoc', { ns: '__urls' }) }}
                  target="_blank">
                  <span className="text pl-0">
                    {t('dashboards.pharma.productdoc_label')}
                  </span>
                  <Icons show="arrow-right"></Icons>
                </Link>
              </li>
              <li>
                <Link
                  className="external block"
                  to={{ pathname: t('productoverview', { ns: '__urls' }) }}
                  target="_blank">
                  <span className="text pl-0">
                    {t('dashboards.pharma.productoverview_label')}
                  </span>
                  <Icons show="arrow-right"></Icons>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DashboardPharma;
