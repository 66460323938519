export const EventSystem = {};
EventSystem.listeners = [];
EventSystem.AddListener = function (callback, filter) {
  for (var i = 0; i < this.listeners.length; i++) {
    if (this.listeners[i].filter == filter) {
      this.listeners[i].callback = callback;
      return;
    }
  }
  this.listeners.push({ callback: callback, filter: filter });
};
EventSystem.Trigger = function (filter, args) {
  for (var i = 0; i < this.listeners.length; i++) {
    var o = this.listeners[i];
    if (filter == o.filter) {
      o.callback.apply(null, [args]);
    }
  }
};
