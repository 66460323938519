import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icons from 'components/misc/icons';

const NavigationPatient = () => {
  let location = useLocation();
  const { t } = useTranslation('__global');

  return (
    <nav>
      <Link
        to="/"
        id="sidebar-dashboard"
        className={`${location.pathname === '/' ? 'active' : ''}`}>
        <Icons show="home" />{' '}
        <span className="text">{t('base.dashboard')}</span>
      </Link>
      <Link
        to="/logbook"
        className={`${location.pathname === '/logbook' ? 'active' : ''}`}>
        <Icons show="clipboard" />{' '}
        <span className="text">{t('logbook.title')}</span>
      </Link>
      <Link
        to="/devices"
        className={`${location.pathname === '/devices' ? 'active' : ''}`}>
        <Icons show="airplay" />{' '}
        <span className="text">{t('base.devices')}</span>
      </Link>
      <hr />
    </nav>
  );
};
export default NavigationPatient;
