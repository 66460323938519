import React from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getAxiosConfig, useProfile } from 'utils/index';
import PanelTop from 'components/layout/panel-top';
import LoadingIndicator from 'components/loaders/indicator';
import ProfileForm from 'components/organisms/form-profile';

const Profile = () => {
  const { t } = useTranslation('__global');
  const profile = useProfile();

  const handleSubmit = (fields) => (event) => {
    event.preventDefault();
    var data = {};
    for (var field in fields) {
      if (fields[field].value) {
        data[fields[field].key] = fields[field].value;
      } else {
        data[fields[field].key] = null;
      }
    }
    axios
      .post(
        process.env.REACT_APP_API_URL + 'users/update/' + profile.data.id,
        data,
        getAxiosConfig(),
      )
      .then((response) => {
        if (response.data.warning === 'W_USER_EMAIL_EXISTS') {
          toast.warn(t('warning.W_USER_EMAIL_EXISTS'));
        }
      });

    toast.success(t('base.saved'));
  };

  return (
    <div className="view">
      <PanelTop left={<h1>{t('base.profile')}</h1>} />
      <div className="grid content">
        {profile.loading ? (
          <LoadingIndicator />
        ) : (
          <ProfileForm
            email={profile.data.email || ''}
            phone={profile.data.phone || ''}
            forename={profile.data.forename || ''}
            lastname={profile.data.lastname || ''}
            street={profile.data.street || ''}
            street_no={profile.data.street_no || ''}
            zip={profile.data.zip || ''}
            place={profile.data.place || ''}
            handleSubmit={handleSubmit}
            formLabel={t('base.save')}
            hideBack={true}
          />
        )}
      </div>
    </div>
  );
};

export default Profile;
