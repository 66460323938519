import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PatientCreateTreatment from 'components/modals/patient/create-treatment';
import PatientCreateDiagnosis from 'components/modals/patient/create-diagnosis';
import PatientCreateAnamnesis from 'components/modals/patient/create-anamnesis';

import '../../theme/components/patient-actions.scss';
import ButtonIcon from 'components/misc/button-icon';

const PatientTopActions = ({ patient, refresh = () => {} }) => {
  const { t } = useTranslation('__global');
  const [showOverlay, setShowOverlay] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const toggleActions = () => {
    setShowOverlay(!showOverlay);
  };
  const modal = (content) => {
    setShowOverlay(false);
    setShowModal(true);
    setModalContent(content);
  };
  const closeModal = () => {
    setShowModal(false);
    setModalContent('');
    refresh();
  };
  const renderModal = () => {
    switch (modalContent) {
      case 'treatment':
        return (
          <PatientCreateTreatment
            patient={patient}
            afterSubmission={closeModal}
          />
        );
      case 'diagnosis':
        return (
          <PatientCreateDiagnosis
            patient={patient}
            afterSubmission={closeModal}
          />
        );
      case 'anamnesis':
        return (
          <PatientCreateAnamnesis
            patient={patient}
            afterSubmission={closeModal}
          />
        );
      default:
        return <p>{`Unknown Modal Content: ` + modalContent}</p>;
    }
  };
  return (
    <div
      className={`caladrius-modal patient-actions ${showModal ? 'show' : ''}`}>
      <div className="fadeout" onClick={closeModal}></div>
      <div className="modal">
        <div className="container">
          <ButtonIcon icon="x" action={closeModal} variation="icon" />
          <div className="content">{renderModal()}</div>
        </div>
      </div>
      <div className="patient-action-button">
        <div className={`overlay ${showOverlay ? '' : 'hidden'}`}>
          <button
            onClick={() => {
              modal('treatment');
            }}>
            {t('patients.treatment')}
          </button>
          <button
            onClick={() => {
              modal('appointment');
            }}>
            {t('patients.appointment')}
          </button>
          <button
            onClick={() => {
              modal('anamnesis');
            }}>
            {t('patients.anamnesis_note')}
          </button>
          <button
            onClick={() => {
              modal('diagnosis');
            }}>
            {t('patients.diagnosis')}
          </button>
        </div>
        <ButtonIcon variation="prominent" icon="plus" action={toggleActions} />
      </div>
    </div>
  );
};
export default PatientTopActions;
