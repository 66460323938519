import PrivateRoute from 'components/routes/private';
import React from 'react';
import { Switch } from 'react-router-dom';
import Patients from 'views/hcp/patients';
import PatientsDetail from 'views/hcp/patients-detail';
import PatientsDetailAnamnesis from 'views/hcp/patients-detail-anamnesis';
import PatientsDetailPersonal from 'views/hcp/patients-detail-personal';
import PatientsDetailTherapy from 'views/hcp/patients-detail-therapy';
import UsersCreate from 'views/root/users-create';
import RoutesGeneral from './general';

const RoutesHCP = () => {
  return (
    <Switch>
      <PrivateRoute component={Patients} path="/patients" exact />
      <PrivateRoute component={PatientsDetail} path="/patients/:userId" exact />
      <PrivateRoute component={UsersCreate} path="/users/new" exact />
      <PrivateRoute
        component={PatientsDetailTherapy}
        path="/patients/:userId/therapy"
        exact
      />
      <PrivateRoute
        component={PatientsDetailAnamnesis}
        path="/patients/:userId/anamnesis"
        exact
      />
      <PrivateRoute
        component={PatientsDetailPersonal}
        path="/patients/:userId/personal_information"
        exact
      />

      <RoutesGeneral />
    </Switch>
  );
};
export default RoutesHCP;
