import React from 'react';
import { Link } from 'react-router-dom';

import '../../theme/components/button-icon.scss';
import Icons from './icons';

const ButtonIcon = ({
  dataIndex = false,
  link = 'undefined',
  icon = 'person',
  variation = false,
  action = undefined,
}) => {
  if (link === 'undefined' || action) {
    return (
      <div
        className={`icon-button no-decoration ${variation ? variation : ''}`}
        onClick={action}
        data-index={dataIndex ? dataIndex : false}>
        <Icons show={icon} />
      </div>
    );
  }
  return (
    <Link
      to={link}
      className={`icon-button no-decoration ${variation ? variation : ''}`}
      data-index={dataIndex ? dataIndex : false}>
      <Icons show={icon} />
    </Link>
  );
};

export default ButtonIcon;
