import React from 'react';

const Icons = ({
  show = 'star',
  color = false,
  hasOnClick = false,
  onClick = () => {},
}) => {
  let classes = `icon icon-${show} ${hasOnClick ? 'mouse-pointer' : ''}`;
  if (color) {
    classes += ' ' + color;
  }

  return <span className={classes} onClick={onClick}></span>;
};

export default Icons;
