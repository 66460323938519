import DefinitionListEntry from 'components/misc/definition-list-entry';
import Icons from 'components/misc/icons';
import CardDeviceType from 'components/cards/device-type';
import useDevice from 'hooks/device';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMoments } from 'utils/dates';
import useUser from 'components/forms/user';

const DeviceFactsListing = ({ deviceId }) => {
  const { t } = useTranslation('__global');
  const [additional, setAdditional] = useState({});
  const device = useDevice(deviceId);
  const dater = useMoments();
  const user = useUser(device.user_id);

  useEffect(() => {
    if (device.injection) {
      // check if device.injection[0].additional is an object
      if (typeof device.injection[0].additional === 'object') {
        setAdditional(device.injection[0].additional);
      } else {
        setAdditional(JSON.parse(device.injection[0].additional));
      }
    }
  }, [device]);

  return (
    <div className="device-details">
      <div className="image-left">
        <CardDeviceType type={device.type} display="big-image-only" />
      </div>
      <dl>
        <DefinitionListEntry
          label={t('devicefacts.id')}
          value={device.external_device_id}
        />
        <DefinitionListEntry
          label={t('devicefacts.user_id')}
          value={`${user.forename} ${user.lastname} - ${user.email} - ${user.id}`}
        />
        <DefinitionListEntry
          label={t('devicefacts.status')}
          value={
            device.injection ? t('devicefacts.used') : t('devicefacts.unused')
          }
        />
        <DefinitionListEntry
          label={t('devicefacts.type')}
          value={device.type}
        />
        <DefinitionListEntry
          label={t('devicefacts.date')}
          value={`${dater(device.last_sync + 'Z').format('l')} ${dater(
            device.last_sync + 'Z',
          ).format('LTS')}`}
        />
        {device.injection &&
        device.injection[0].data.injection_data &&
        device.injection[0].data.injection_data?.decoded ? (
          <>
            <DefinitionListEntry
              label={t('devicefacts.duration')}
              value={
                (
                  device.injection[0].data.injection_data.decoded.timeMS / 1000
                ).toFixed(2) + 's'
              }
            />
            <DefinitionListEntry
              label={t('devicefacts.injdate')}
              value={`${dater(device.injection[0].date + 'Z').format(
                'l',
              )} ${dater(device.injection[0].date + 'Z').format('LTS')}`}
            />
            <DefinitionListEntry
              label={t('devicefacts.rssi')}
              value={additional.rssi + ' dB'}
            />
            {/* DEACTIVATED - CALA-41 <DefinitionListEntry
              label={t('devicefacts.distance')}
              value={additional.distance + ' m'}
              />*/}
            <DefinitionListEntry
              label={t('devicefacts.duplicate_status')}
              value={
                device.injection[0].validation == 'CHALLENGE' ? (
                  <>
                    <Icons show="alert-triangle" />
                    {` `}
                    <strong>{device.injection[0].validation}</strong>
                  </>
                ) : (
                  device.injection[0].validation
                )
              }
            />
          </>
        ) : (
          <></>
        )}
        {device.injection && device.injection[0].data.device_data ? (
          <>
            <DefinitionListEntry
              label={t('devicefacts.duration')}
              value={
                (
                  device.injection[0].data.device_data.decoded.time_ms / 1000
                ).toFixed(2) + 's'
              }
            />
            <DefinitionListEntry
              label={t('devicefacts.injdate')}
              value={`${dater(device.injection[0].date + 'Z').format(
                'l',
              )} ${dater(device.injection[0].date + 'Z').format('LTS')}`}
            />
            <DefinitionListEntry
              label={t('devicefacts.rssi')}
              value={additional.rssi + ' dB'}
            />
            <DefinitionListEntry
              label={t('devicefacts.distance')}
              value={additional.distance + ' m'}
            />
            <DefinitionListEntry
              label={t('devicefacts.voltage')}
              value={
                (
                  device.injection[0].data.device_data.decoded.voltage / 1000
                ).toFixed(3) + 'V'
              }
            />
            <DefinitionListEntry
              label={t('devicefacts.voltage_diff')}
              value={
                (
                  device.injection[0].data.device_data.decoded.voltage_diff /
                  1000
                ).toFixed(3) + 'V'
              }
            />
          </>
        ) : (
          <></>
        )}
      </dl>
    </div>
  );
};

export default DeviceFactsListing;
