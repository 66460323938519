import PrivateRoute from 'components/routes/private';
import React from 'react';
import { Switch } from 'react-router-dom';
import DeviceDetail from 'views/pharma/device';
import Devices from 'views/pharma/devices';
import RoutesGeneral from './general';

const RoutesPharma = () => {
  return (
    <Switch>
      <PrivateRoute component={Devices} path="/devices" exact />
      <PrivateRoute component={DeviceDetail} path="/device/:id" exact />
      <RoutesGeneral />
    </Switch>
  );
};
export default RoutesPharma;
