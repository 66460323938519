import PrivateRoute from 'components/routes/private';
import React from 'react';
import { Switch } from 'react-router-dom';
import Users from 'views/root/users';
import UsersCreate from 'views/root/users-create';
import UsersEdit from 'views/root/users-edit';
import RoutesGeneral from './general';

const RoutesRoot = () => {
  return (
    <Switch>
      <PrivateRoute component={Users} path="/users" exact />
      <PrivateRoute component={UsersCreate} path="/users/new" exact />
      <PrivateRoute component={UsersEdit} path="/users/edit/:userId" exact />
      <RoutesGeneral />
    </Switch>
  );
};
export default RoutesRoot;
