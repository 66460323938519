import ButtonIcon from 'components/misc/button-icon';
import Tag from 'components/misc/tag';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { padNum } from 'utils/index';

import '../../theme/components/card-user.scss';

const CardPatient = (props) => {
  let profile = props.profile;
  let link = '/patients/' + profile.id;
  const history = useHistory();

  function handleClick(e) {
    if (e.target.href === undefined) {
      history.push(link);
    }
  }

  let address = '';
  if (profile.street || profile.street_no || profile.zip || profile.place) {
    var streetAndNo = [profile.street || '', profile.street_no || ''].join(' ');
    var zipAndPlace = [profile.zip || '', profile.place || ''].join(' ');
    streetAndNo = streetAndNo.length === 1 ? false : streetAndNo;
    zipAndPlace = zipAndPlace.length === 1 ? false : zipAndPlace;
    let a = [streetAndNo, zipAndPlace];
    address = a.filter(Boolean).join(', ');
  }

  return (
    <div
      className={
        'user-card patient card ' + (profile.active === 0 ? 'deactivated' : '')
      }
      onClick={handleClick}>
      <div className="v-stack col-s-1 col-e-5">
        <strong>
          {profile.forename} {profile.lastname}
        </strong>
        <small>{profile.email}</small>
      </div>
      <div className="v-stack col-s-5 col-e-11">
        <span>{address}</span>
        {typeof profile.phone === 'string' ? (
          <small>
            <a href={'tel:' + profile.phone.replace(/ /g, '')}>
              {profile.phone}
            </a>
          </small>
        ) : (
          ''
        )}
      </div>
      <div className="flex-end align-center col-s-11 col-e-13 flex-space">
        <Tag value={'P-' + padNum(profile.id, '0', 4)} />{' '}
        <ButtonIcon icon="pencil" />
      </div>
    </div>
  );
};

export default CardPatient;
