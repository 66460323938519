import React from 'react';

import '../../theme/components/loading-indicator.scss';

const LoadingIndicator = ({ spaced = false }) => {
  return (
    <div className={`loader-6 loader-component ${spaced ? 'spaced' : ''}`}>
      <span></span>
    </div>
  );
};

export default LoadingIndicator;
