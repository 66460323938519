import PanelTop from 'components/layout/panel-top';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

const DashboardHCP = ({ profile }) => {
  const { t } = useTranslation('__global');
  return (
    <div className="view">
      <PanelTop
        left={
          <>
            <small className="uppercase special">
              {t('dashboards.default.subtitle')}
            </small>
            <h1>
              <Trans
                t={t}
                i18nKey="dashboards.default.title"
                values={{
                  user: `${profile.data.forename} ${
                    profile.data.lastname
                      ? profile.data.forename
                        ? profile.data.lastname
                        : ''
                      : ''
                  }`,
                }}
              />
            </h1>
          </>
        }
      />
    </div>
  );
};
export default DashboardHCP;
