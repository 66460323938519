import axios from 'axios';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { getAxiosConfig } from 'utils/index';
import Notifications from 'views/shared/notifications';

import '../../theme/components/card-profile.scss';
import { EventSystem } from 'utils/eventsystem';

const CardProfile = ({ profile = false }) => {
  const [showNotifications, setShowNotifications] = useState(false);
  const [notificationInitial, setNotificationInitial] = useState(true);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationList, setNotificationList] = useState([]);
  const { t } = useTranslation('__global');

  const toggleNotifications = (event) => {
    event.preventDefault();
    setNotificationInitial(false);
    setShowNotifications(!showNotifications);

    setTimeout(() => {
      if (!showNotifications) {
        var idList = [];
        for (var i = 0; i < notificationList.length; i++) {
          var item = notificationList[i];
          if (item.level < 3 && item.read == null) {
            idList.push(item.id);
          }
        }
        if (idList.length > 0) {
          markAsRead(idList, true, false);
        }
      }
    }, 2000);
  };

  const closeClick = () => {
    setShowNotifications(false);
  };

  const fetchData = async () => {
    const result = await axios.get(
      process.env.REACT_APP_API_URL + 'notification/get?minlevel=0',
      getAxiosConfig(),
    );
    setNotificationList(result.data);
    var lev3unread = updateUnreadCount(result.data);
    EventSystem.Trigger('notificationListChange', lev3unread);
  };

  const markAsReadHandler = (id) => {
    markAsRead([id], true, false);
  };
  EventSystem.AddListener(markAsReadHandler, 'markAsRead');

  useEffect(() => {
    fetchData();
    setInterval(fetchData, 10000);
  }, []);

  const readSingleClick = (event, id) => {
    markAsRead([id], true, false);
  };

  const markAsRead = (idList, refresh, showToast) => {
    for (var i = 0; i < notificationList.length; i++) {
      var item = notificationList[i];
      if (idList.indexOf(String(item.id)) > -1) {
        item.read = new Date();
      }
    }
    setNotificationList(notificationList);
    updateUnreadCount(notificationList);
    axios
      .post(
        process.env.REACT_APP_API_URL + 'notification/mark_read',
        {
          notification_id: idList,
        },
        getAxiosConfig(),
      )
      .then((response) => {
        console.log(response);
        if (refresh) {
          fetchData();
        }
        if (showToast) {
          toast.success(t('notification.singleread'));
        }
      });
  };
  const moreSingleClick = (event, data) => {
    window.open(data, '_blank');
    console.log('moreSingleClick ' + ' ' + data);
  };

  const updateUnreadCount = (list) => {
    var count = 0;
    var unreadLevel3 = [];
    for (var i = 0; i < list.length; i++) {
      if (list[i].read == null) {
        count++;
        if (list[i].level == 3) {
          unreadLevel3.push(list[i]);
        }
      }
    }
    setNotificationCount(count);
    return unreadLevel3;
  };

  return (
    <div className="profile-card">
      <Link to="/profile" className="profile-text">
        <p className="strong">
          {profile.forename} {profile.lastname}
        </p>
        <small className="discreet">{t(profile.role)}</small>
      </Link>
      <div className="profile-notification" onClick={toggleNotifications}>
        {notificationCount > 0 ? (
          <div className="profile-notification-number">{notificationCount}</div>
        ) : (
          ''
        )}
        <div className="icon icon-bell"></div>
      </div>
      <Notifications
        notificationList={notificationList}
        readSingleClick={readSingleClick}
        moreSingleClick={moreSingleClick}
        closeClick={closeClick}
        isVisible={showNotifications}
        isInitial={notificationInitial}
      />
    </div>
  );
};

export default CardProfile;
