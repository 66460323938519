import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Trans, useTranslation } from 'react-i18next';

import { useMoments } from 'utils/dates';
import { getAxiosConfig } from 'utils/index';
import { EventSystem } from 'utils/eventsystem';
import PanelTop from 'components/layout/panel-top';
import LoadingIndicator from 'components/loaders/indicator';
import CardNextInjection from 'components/cards/injection-next';
import Button from 'components/misc/button';
import MessageInline from 'components/misc/message-inline';
import CardDevice from 'components/cards/device';
import TherapyTimeline from 'components/organisms/therapy-timeline';
import CardDiagnosis from 'components/cards/diagnosis';
import CardDoctor from 'components/cards/doctor';

import '../../theme/views/dashboard-patient.scss';
import useDevices from 'hooks/devices';

const DashboardPatient = ({ profile }) => {
  const { t } = useTranslation('__global');
  const dater = useMoments();
  const [loading, setLoading] = useState(true);
  const devices = useDevices();
  const [diagnosis, setDiagnosis] = useState([]);
  const [nextInjection, setNextInjection] = useState(false);
  const [notificationList, setNotificationList] = useState([]);

  const loadTherapy = async () => {
    const therapyResult = await axios.get(
      process.env.REACT_APP_API_URL +
        '/event/get/therapy/' +
        profile.data.id +
        '?order=asc&limit=30&start=today',
      getAxiosConfig(),
    );
    for (var index in therapyResult.data.events) {
      var current_event = therapyResult.data.events[index];
      if (current_event.type != 'schedule_without_injection') {
        continue;
      }
      // check for first date in the future.
      if (dater(current_event.start + 'Z').diff(new Date()) > 0) {
        setNextInjection(current_event);
        break;
      }
    }
    const diagnosisResult = await axios.get(
      process.env.REACT_APP_API_URL + '/diagnosis/get/' + profile.data.id,
      getAxiosConfig(),
    );
    setDiagnosis(diagnosisResult.data);
    setLoading(false);
  };

  const onNotificationListChanged = (nList) => {
    setNotificationList(nList);
  };
  EventSystem.AddListener(onNotificationListChanged, 'notificationListChange');

  const markAsReadAction = (id) => {
    var nList = [];
    for (var i = 0; i < notificationList.length; i++) {
      if (String(notificationList[i].id) != String(id)) {
        nList.push(notificationList[i]);
      }
    }
    setNotificationList(nList);
    EventSystem.Trigger('markAsRead', id);
  };
  const moreInfoAction = (link) => {
    window.open(link, '_blank');
  };

  useEffect(() => {
    loadTherapy();
  }, []);

  return (
    <div className="view">
      <PanelTop
        loading={profile.loading}
        left={
          <>
            <small className="uppercase special">
              {t('dashboards.patient.subtitle')}
            </small>
            <h1>
              <Trans
                t={t}
                i18nKey="dashboards.patient.title"
                values={{
                  patient: `${profile.data.forename} ${
                    profile.data.lastname
                      ? profile.data.forename
                        ? profile.data.lastname
                        : ''
                      : ''
                  }`,
                }}></Trans>
            </h1>
          </>
        }
      />
      <div className="content patient-dashboard">
        {profile.loading || loading ? (
          <LoadingIndicator />
        ) : (
          <div>
            <div className="flex">
              <CardNextInjection event={nextInjection} />
              <div className="devices">
                <div className="headline flex splitted">
                  <h3>{t('dashboards.patient.devices')}</h3>
                  <div className="actions flex">
                    <Button
                      label={t('dashboards.patient.to_all_devices')}
                      icon="arrow-right"
                      link="/devices"
                    />
                    <Button
                      label={t('dashboards.patient.to_add_new')}
                      icon="arrow-right"
                      link="/devices"
                      type="prominent"
                    />
                  </div>
                </div>
                <div className="device-listing fadeinup">
                  {devices.items.length === 0 ? (
                    <MessageInline
                      type="discreet"
                      message={t('dashboards.patient.no_devices')}
                    />
                  ) : (
                    <div className="flex mt-base">
                      {devices.items.map((device) =>
                        device.single_use === true ? (
                          <></>
                        ) : (
                          <CardDevice key={device.id} data={device} />
                        ),
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-large">
              {notificationList.map((notification) => (
                <MessageInline
                  key={notification.id}
                  icon="alert-octagon"
                  type="error"
                  title={notification.title}
                  message={notification.text}
                  action={
                    notification.link != null
                      ? (event) => {
                          moreInfoAction(notification.link, event);
                        }
                      : false
                  }
                  action_label={t('notification.readmore')}
                  actionRead={() => {
                    markAsReadAction(notification.id);
                  }}
                  actionRead_label={t('notification.markread')}
                />
              ))}
            </div>
            {/*<div className="mt-large">
              <InlineMessage
                icon="alert-octagon"
                type="error"
                title={t('dashboards.patient.recall_title')}
                message={t('dashboards.patient.recall_message')}
                action={() => {}}
              />
            </div>*/}
            <TherapyTimeline />
            <div className="flex diagnosis-doctor">
              <div className="diagnosis-cards">
                {diagnosis.map((dia) => (
                  <CardDiagnosis key={dia.id} diagnosis={dia} />
                ))}
              </div>
              <CardDoctor />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default DashboardPatient;
