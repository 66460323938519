import Button from 'components/misc/button';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useMoments } from 'utils/dates';

import '../../theme/components/card-doctor.scss';

const CardNextInjection = ({ event = null }) => {
  const { t } = useTranslation('__global');

  const dater = useMoments();

  const schedule_next_days = dater(event.start + 'Z').fromNow();
  const schedule_next_date = dater(event.start + 'Z').calendar();

  console.log(event);

  return (
    <div className="next-date card flat">
      <h2>
        <Trans t={t} i18nKey="dashboards.patient.next">
          your next injection is scheduled{' '}
          <strong>{{ schedule_next_days }}</strong>, on{' '}
          <strong>{{ schedule_next_date }}</strong>
        </Trans>
      </h2>
      <Button
        label={t('dashboards.patient.to_schedule')}
        icon="arrow-right"
        link="/todo"
        type="prominent"
      />
    </div>
  );
};

export default CardNextInjection;
