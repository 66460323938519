import React from 'react';
import '../../theme/components/service-detail.scss';
import ValuePill from './value-pill';
import ServiceHistoryChart from '../charts/service-history-chart';
import moment from 'moment';
import Icons from './icons';
import useOptions from '../../hooks/options';
import { useHistory } from 'react-router-dom';

const defaultOptions = {
  hasGraph: false,
  hasDeleteIcon: true,
  hasEditIcon: true,
};

const ServiceDetail = ({
  service,
  averageResponseTime,
  onEditPressed = () => {},
  onDeletePressed = () => {},
  config = defaultOptions,
}) => {
  const options = useOptions(defaultOptions, config);
  const history = useHistory();

  return (
    <div
      className="card service-detail"
      onClick={() => history.push(`/services/${service._id}`)}>
      <div className="pills">
        <ValuePill
          value={`${
            service.responses.length > 0
              ? service.responses[0].is_success
                ? 'ONLINE'
                : 'OFFLINE'
              : 'UNKNOWN'
          }`}
          style={`${
            service.responses.length > 0
              ? service.responses[0].is_success
                ? 'green'
                : 'red'
              : 'yellow'
          }`}
        />
        <ValuePill value={`${service.technology}`} />
        <ValuePill value={`${service.abbreviation}`} />
      </div>
      <div className={'flex align-center title-actions'}>
        <h2>{service.name}</h2>
        {options.hasEditIcon && (
          <Icons
            show={'edit'}
            hasOnClick={true}
            onClick={(e) => {
              e.stopPropagation();
              onEditPressed(service);
            }}
          />
        )}
        {options.hasDeleteIcon && (
          <Icons
            show={'delete'}
            hasOnClick={true}
            onClick={(e) => {
              e.stopPropagation();
              onDeletePressed(service);
            }}
          />
        )}
      </div>
      <div className="card-item">
        <small>Software Version</small>
        <p>
          {service.responses.length > 0 && service.responses[0].service_version
            ? service.responses[0].service_version
            : 'Unknown'}
        </p>
        <div className={'splitline'} />
      </div>
      <div className="card-item">
        <small>Average Ping Last 24 Hours</small>
        <p>
          {averageResponseTime
            ? `${Math.round(averageResponseTime)}ms`
            : 'No Responses in last 24 Hours'}
        </p>
        <div className={'splitline'} />
      </div>
      <div className="card-item">
        <small>Endpoint</small>
        <p>{service.url}</p>
        <div className={'splitline'} />
      </div>
      {options.hasGraph && (
        <div className="card-item">
          <small>This Week</small>
          <div className="response-graph">
            <ServiceHistoryChart
              services={[service]}
              from={moment().startOf('week').unix()}
              to={moment().endOf('week').unix()}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default ServiceDetail;
