import React from 'react';
import { createBrowserHistory } from 'history';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import '../../theme/components/button-back.scss';

const ButtonBack = ({ label = false, to = false }) => {
  let { t } = useTranslation('__global');
  let his = useHistory();

  if (!label) label = t('base.back');

  const buttonAction = (event) => {
    event.preventDefault();
    if (to) {
      his.push(to);
    } else {
      let history = createBrowserHistory();
      history.goBack();
    }
  };

  return (
    <div className="back-button">
      <a href="#!" className="small uppercase" onClick={buttonAction}>
        {label}
      </a>
    </div>
  );
};
export default ButtonBack;
