import Editor from 'components/forms/editor';
import Button from 'components/misc/button';
import React, { useState } from 'react';

const CardInjectionTechnical = ({ data = {} }) => {
  const [showCompleteData, setShowCompleteData] = useState(false);

  return (
    <div className="card">
      <dl>
        <dt>Injection ID</dt>
        <dd>{data.id}</dd>
        <dt>Device ID</dt>
        <dd>{data.external_device_id}</dd>
      </dl>
      {showCompleteData ? (
        <Editor
          value={JSON.stringify(data, null, 4)}
          label=""
          readOnly={true}
          height={200}
        />
      ) : (
        <></>
      )}
      <Button
        icon={showCompleteData ? 'minus-circle' : 'plus-circle'}
        label={showCompleteData ? 'Hide Data' : 'Show Complete Data'}
        action={() => {
          setShowCompleteData(!showCompleteData);
        }}
      />
    </div>
  );
};
export default CardInjectionTechnical;
