import LoadingIndicator from 'components/loaders/indicator';
import React from 'react';
import Icons from './icons';

const ButtonLink = ({
  icon = 'pencil',
  action = () => {
    console.log('no link button action');
  },
  label = 'label',
  loading = false,
  data_index = '',
  className = 'button default ',
}) => {
  const run = (event) => {
    event.preventDefault();
    var data = event.target.getAttribute('data-index');
    action(event, data);
  };

  return (
    <a
      className={className + (loading ? 'loading' : '')}
      onClick={run}
      href="#!"
      data-index={data_index}>
      {label}
      {icon != null ? <Icons show={icon} /> : ''}

      {loading ? <LoadingIndicator /> : ''}
    </a>
  );
};

export default ButtonLink;
