import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icons from 'components/misc/icons';

const NavigationYpsomed = () => {
  let location = useLocation();
  const { t } = useTranslation('__global');

  return (
    <nav>
      <Link
        to="/"
        id="sidebar-dashboard"
        className={`${location.pathname === '/' ? 'active' : ''}`}>
        <Icons show="home" />
        <span className="text">{t('base.dashboard')}</span>
      </Link>
      <Link
        to="/devices"
        className={`${location.pathname === '/devices' ? 'active' : ''}`}>
        <Icons show="server" />
        <span className="text">{t('base.devices')}</span>
      </Link>
      <Link
        to="/drugrecall"
        className={`${location.pathname === '/drugrecall' ? 'active' : ''}`}>
        <Icons show="repeat" />{' '}
        <span className="text">{t('base.drugrecall')}</span>
      </Link>
      {process.env.REACT_APP_LOGS_ENABLED === 'true' && (
        <Link
          to="/logs"
          className={`${location.pathname === '/logs' ? 'active' : ''}`}>
          <Icons show="inbox" />
          <span className="text">{t('base.logs')}</span>
        </Link>
      )}
      <Link
        to="/tools"
        className={`${location.pathname === '/tools' ? 'active' : ''}`}>
        <Icons show="tool" /> <span className="text">{t('base.tools')}</span>
      </Link>
      <Link
        to="/services"
        className={`${location.pathname === '/services' ? 'active' : ''}`}>
        <Icons show="activity" />{' '}
        <span className="text">{t('base.services')}</span>
      </Link>
      <Link
        to="/environments"
        className={`${location.pathname === '/environments' ? 'active' : ''}`}>
        <Icons show="grid" />{' '}
        <span className="text">{t('base.environments')}</span>
      </Link>
      <Link
        to="/users"
        className={`${location.pathname === '/users' ? 'active' : ''}`}>
        <Icons show="users" />
        <span className="text">{t('base.users')}</span>
      </Link>
      <hr />
    </nav>
  );
};
export default NavigationYpsomed;
