import React from 'react';

import '../../theme/components/definition-list.scss';

const DefinitionListEntry = ({ label, value, display }) => {
  if (display === false) {
    return null;
  }
  return (
    <div>
      <dt>{label}</dt>
      <dd>{value}</dd>
    </div>
  );
};
export default DefinitionListEntry;
