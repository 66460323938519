import axios from 'axios';
import Input from 'components/forms/input';
import Button from 'components/misc/button';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getAxiosConfig, useProfile } from 'utils/index';
import BoxMessage from './box-message';

const BoxAddDevice = ({ onSuccess = () => {} }) => {
  const { t } = useTranslation('__global');
  const [deviceId, setDeviceId] = useState();
  const [deviceIdError, setDeviceIdError] = useState(false);
  const [addDeviceByIdLoading, setAddDeviceByIdLoading] = useState(false);
  const [addDeviceByQRLoading, setAddDeviceByQRLoading] = useState(false);
  const [phoneNo, setPhoneNo] = useState();

  useProfile((data) => {
    if (data.phone && data.phone !== '') {
      setPhoneNo(data.phone);
    }
  });

  const updateDeviceId = (event) => {
    var id = event.target.value;
    id = id.replaceAll(' ', '');
    setDeviceId(id);
  };

  const updatePhoneNo = (event) => {
    setPhoneNo(event.target.value);
  };

  const handleAddDeviceByID = (event) => {
    event.preventDefault();
    if (!deviceId) {
      return;
    }
    setAddDeviceByIdLoading(true);

    axios
      .post(
        process.env.REACT_APP_API_URL + 'devices/add',
        {
          device: deviceId,
        },
        getAxiosConfig(),
      )
      .then((response) => {
        if (response.data.status !== 'SUCCESS') {
          // trigger window event "initUpdateData"
          setDeviceIdError(response.data.status);
        } else {
          setDeviceIdError(false);
          setDeviceId('');
          toast.success(t('devices.addbox.added'));
          window.dispatchEvent(new Event('initUpdateData'));
          onSuccess();
        }
        setAddDeviceByIdLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data.status);
      });
  };

  const handleAddDeviceByQR = (event) => {
    event.preventDefault();
    if (!phoneNo) {
      return;
    }
    setAddDeviceByQRLoading(true);
    axios
      .get(
        process.env.REACT_APP_API_URL +
          'devices/onboarding/qr_send?phone=' +
          phoneNo,
        getAxiosConfig(),
      )
      .then((response) => {
        console.log(response);
        toast.success(t('devices.addbox.sms_sent'));
        setPhoneNo('');
        setAddDeviceByQRLoading(false);
      });
  };

  const addDeviceByIDForm = (
    <div>
      <form onSubmit={handleAddDeviceByID}>
        <Input
          name="device_id"
          icon="devices"
          label={t('devices.id')}
          value={deviceId}
          handleChange={updateDeviceId}
          error={deviceIdError ? t('error.' + deviceIdError) : ''}
        />
        <Button
          label={t('devices.save_device')}
          type="default"
          icon="arrow-right"
          loading={addDeviceByIdLoading}
          splitted
        />
      </form>
    </div>
  );

  const addDeviceByQRForm = (
    <div>
      <form onSubmit={handleAddDeviceByQR}>
        <Input
          name="phone_number"
          icon="phone"
          label={t('base.phone')}
          value={phoneNo}
          handleChange={updatePhoneNo}
          error=""
        />
        <Button
          label={t('devices.send_link')}
          type="default"
          icon="arrow-right"
          loading={addDeviceByQRLoading}
          splitted
        />
      </form>
    </div>
  );

  return (
    <BoxMessage
      primary={{
        title: t('devices.addbox.id.title'),
        text: t('devices.addbox.id.text'),
        illustration: 'typing',
        more: addDeviceByIDForm,
      }}
      secondary={{
        title: t('devices.addbox.qr.title'),
        text: t('devices.addbox.qr.text'),
        illustration: 'qr',
        more: addDeviceByQRForm,
      }}
    />
  );
};
export default BoxAddDevice;
