import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icons from 'components/misc/icons';

const NavigationHCP = () => {
  let location = useLocation();
  const { t } = useTranslation('__global');

  return (
    <nav>
      <Link
        to="/"
        id="sidebar-dashboard"
        className={`${location.pathname === '/' ? 'active' : ''}`}>
        <Icons show="home" />{' '}
        <span className="text">{t('base.dashboard')}</span>
      </Link>
      <Link
        to="/patients"
        className={`${location.pathname === '/patients' ? 'active' : ''}`}>
        <Icons show="user" />
        <span className="text">{t('base.patients')}</span>
      </Link>
      <hr />
    </nav>
  );
};
export default NavigationHCP;
