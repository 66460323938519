import LoadingIndicator from 'components/loaders/indicator';
import React from 'react';
import { Link } from 'react-router-dom';

import '../../theme/components/button.scss';
import Icons from './icons';

const Button = ({
  label = 'Undefined <label>',
  icon = false,
  type = 'default',
  toggleColor = 'default',
  link = false,
  action = () => {},
  splitted = false,
  splitContent = false,
  isLoading = false,
  isStretched = false,
  isToggled = false,
  hasFocus = true,
  extraButtonClasses = '',
  children,
}) => {
  let classes = `button ${type} ${isLoading ? 'loading' : ''} ${
    isStretched ? 'stretch' : ''
  } ${extraButtonClasses} ${isToggled ? 'toggled' : ''} ${
    hasFocus ? 'button-focus' : ''
  } ${toggleColor}`;
  if (link) {
    return (
      <Link className={classes} to={link} type="submit">
        {label} {icon ? <Icons show={icon} /> : ''}
      </Link>
    );
  }

  return (
    <div
      className={`form-element ${splitted ? 'splitted' : ''} ${
        type === 'inline' ? 'inline' : ''
      }`}>
      {splitContent ? splitContent : splitted ? <span>&nbsp;</span> : ''}
      <button className={classes} onClick={action} type="submit">
        {label} {children} {icon ? <Icons show={icon} /> : ''}
        <LoadingIndicator />
      </button>
    </div>
  );
};
export default Button;
