import React, { useEffect, useState } from 'react';
import { useProfile } from 'utils/index';
import RoutesGeneral from './routes/general';
import RoutesHCP from './routes/hcp';
import RoutesPatient from './routes/patient';
import RoutesPharma from './routes/pharma';
import RoutesRoot from './routes/root';
import RoutesYpsomed from './routes/ypsomed';

const MainSwitch = () => {
  const profile = useProfile();
  const [routes, setRoutes] = useState(<></>);

  useEffect(() => {
    if (profile.loading) return;

    switch (profile.data.role) {
      case 'role.ypsomed':
        setRoutes(<RoutesYpsomed />);
        break;

      case 'role.patient':
        setRoutes(<RoutesPatient />);
        break;

      case 'role.hcp':
        setRoutes(<RoutesHCP />);
        break;

      case 'role.root':
        setRoutes(<RoutesRoot />);
        break;

      case 'role.pharma':
        setRoutes(<RoutesPharma />);
        break;

      default:
        setRoutes(<RoutesGeneral />);
        break;
    }
  }, [profile]);

  return <div className="v-stack">{routes}</div>;
};

export default MainSwitch;
