import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useProfile } from 'utils/index';
import PanelTop from 'components/layout/panel-top';
import LoadingIndicator from 'components/loaders/indicator';
import CardDevice from 'components/cards/device';
import BoxAddDevice from 'components/organisms/box-add-device';
import Modal from 'components/misc/modal';
import Button from 'components/misc/button';
import useDevices from 'hooks/devices';

const Devices = () => {
  const { t } = useTranslation('__global');
  const profile = useProfile();
  const devices = useDevices();
  const [triggerReload, setTriggerReload] = useState(1);
  const [showAddModal, setShowAddModal] = useState(false);

  const triggerModalShow = () => {
    setShowAddModal(true);
  };

  const onModalHide = () => {
    setShowAddModal(false);
  };

  const onDeviceAddSuccess = () => {
    setShowAddModal(false);
    setTriggerReload(triggerReload + 1);
  };

  return (
    <div className="view">
      <PanelTop
        loading={profile.loading}
        left={<h1>{t('devices.title')}</h1>}
        right={
          <>
            <Button label={t('devices.hideOffline')} icon="devices" />
            <Button
              type="prominent"
              label={t('devices.add')}
              icon="arrow-right"
              action={triggerModalShow}
            />
          </>
        }
      />
      {devices.items.length === 0 ? (
        <div className="content">
          <LoadingIndicator spaced={true} />
        </div>
      ) : devices.items.length !== 0 ? (
        <div className="grid content">
          {devices.items.map((device) =>
            device.single_use ? (
              <></>
            ) : (
              <CardDevice key={device.id} data={device} />
            ),
          )}
        </div>
      ) : (
        ''
      )}
      {devices.items.length !== 0 ? <hr /> : ''}
      <div className="content">
        <BoxAddDevice onSuccess={onDeviceAddSuccess} />
      </div>
      <Modal
        content={<BoxAddDevice onSuccess={onDeviceAddSuccess} />}
        show={showAddModal}
        onHide={onModalHide}
      />
    </div>
  );
};

export default Devices;
