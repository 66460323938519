import LoadingIndicator from 'components/loaders/indicator';
import React, { useEffect, useState } from 'react';
import { useProfile } from 'utils/index';

import DashboardHCP from './hcp';
import DashboardPatient from './patient';
import DashboardPharma from './pharma';
import DashboardRoot from './root';
import DashboardYpsomed from './ypsomed';

const Dashboard = () => {
  const profile = useProfile();
  const [dashboard, setDashboard] = useState(<></>);

  useEffect(() => {
    if (profile.loading) {
      return;
    }
    switch (profile.data.role) {
      case 'role.patient':
        setDashboard(<DashboardPatient profile={profile} />);
        break;
      case 'role.root':
        setDashboard(<DashboardRoot profile={profile} />);
        break;
      case 'role.pharma':
        setDashboard(<DashboardPharma profile={profile} />);
        break;
      case 'role.ypsomed':
        setDashboard(<DashboardYpsomed profile={profile} />);
        break;
      case 'role.hcp':
        setDashboard(<DashboardHCP profile={profile} />);
        break;
      default:
        setDashboard(<h2>no dashboard defined</h2>);
        break;
    }
  }, [profile]);

  return profile.loading ? (
    <div className="view panel-top">
      <LoadingIndicator />
    </div>
  ) : (
    dashboard
  );
};
export default Dashboard;
