import React from 'react';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/ext-language_tools';

import '../../theme/components/editor.scss';

const Editor = ({
  action = () => {},
  name = 'code-editor',
  language = 'json',
  value = `{"code": "undefined"}`,
  label = 'No Label',
  readOnly = false,
  height = '360px',
  fontSize = 14,
  completions = [],
}) => {
  return (
    <div className="script-editor form-element">
      <label>{label}</label>
      <AceEditor
        placeholder=""
        mode={language}
        theme="github"
        name={name}
        onChange={action}
        fontSize={fontSize}
        showPrintMargin={true}
        showGutter={true}
        highlightActiveLine={true}
        width="100%"
        height={height}
        value={value}
        readOnly={readOnly}
        setOptions={{
          enableBasicAutocompletion: [
            {
              getCompletions: (editor, session, pos, prefix, callback) => {
                callback(null, completions);
              },
            },
          ],
          enableLiveAutocompletion: true,
          enableSnippets: false,
          showLineNumbers: true,
        }}
      />
    </div>
  );
};

export default Editor;
