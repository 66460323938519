import Icons from 'components/misc/icons';
import Tag from 'components/misc/tag';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMoments } from 'utils/dates';

const CardInjectionTimeline = ({ injection }) => {
  const { t } = useTranslation('__global');
  const moment = useMoments();
  const eventMoment = moment(injection.start);
  const [icon, setIcon] = useState(<></>);
  const [status, setStatus] = useState('failed');

  useEffect(() => {
    switch (injection.type) {
      case 'schedule_without_injection':
        setIcon(<Icons show="help-circle" />);
        setStatus('missing');
        break;

      case 'lose_injection':
        setIcon(<Icons show="plus-circle" />);
        setStatus('success');
        break;

      default:
        setIcon(<Icons show="check-circle" />);
        setStatus('success');
        break;
    }
  }, [injection]);

  let newBadge = <></>;
  var iscurrentDate = eventMoment.isSame(new Date(), 'day');
  if (iscurrentDate && injection.type != 'schedule_without_injection') {
    newBadge = <Tag value={t('base.new')} />;
  }
  return (
    <div className="injection">
      <div className="card">
        <div className="top flex">
          {icon}
          {newBadge}
        </div>
        <h4>{t('dashboards.patient.injection.' + injection.type)}</h4>
        <span className="discreet">
          {t('dashboards.patient.injection.' + status)}
        </span>
      </div>
      <div className="time-date flex vertical">
        <span>{eventMoment.format('DD.MM.YYYY')}</span>
        <strong>{eventMoment.format('HH:mm')}</strong>
      </div>
    </div>
  );
};
export default CardInjectionTimeline;
