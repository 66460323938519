import ButtonIcon from 'components/misc/button-icon';
import useUser from 'hooks/user';
import React from 'react';

const CardDrugRecall = ({ data, onDelete }) => {
  const user = useUser(data.pharma_id);

  return (
    <tr className="drug-recall-card">
      <td className="strong">{data.mediUDIregex}</td>
      <td className="">{data.description}</td>
      <td className="">{`${user.forename} ${user.lastname} (${user.id})`}</td>
      <td className="">
        {data.link ? (
          <a href={data.link} target="_blank" rel="noreferrer">
            {data.link}
          </a>
        ) : (
          <></>
        )}
      </td>
      <td>
        <ButtonIcon
          icon="trash"
          action={onDelete}
          dataIndex={data.id}
          variation="flat"
        />
      </td>
    </tr>
  );
};

export default CardDrugRecall;
