import Input from 'components/forms/input';
import Select from 'components/forms/select';
import PanelTop from 'components/layout/panel-top';
import Button from 'components/misc/button';
import ProtoViewer from 'components/misc/protoviewer';
import ToolsNavigation from 'components/navigations/detail/tools';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { pbFileToBase64 } from 'utils';

import fileConfig from './protobufConfig.json';

const ToolsProtobufReader = () => {
  const [config] = useState(fileConfig);
  const [error, setError] = useState('');
  const [proto, setProto] = useState('');
  const [formData, setFormData] = useState({
    data: '',
    definition: '',
  });
  const [result, setResult] = useState(<></>);
  const { t: tGlobal } = useTranslation('__global');
  const { t: tYpsomed } = useTranslation('__ypsomed');

  const handleFileChange = (event) => {
    setFormData({
      ...formData,
      data: event.target.files[0],
    });
  };

  const handleSelectChange = (event) => {
    setFormData({
      ...formData,
      definition: event.target.value,
    });
  };

  const loadProto = async () => {
    // check if select has a value and proto is loaded
    if (formData.definition === '' || formData.data === '' || proto === '') {
      setError(tYpsomed('tools.pbReader.reader_error'));
      return;
    } else {
      setError('');
    }

    const pbString = await pbFileToBase64(formData.data);

    const configObject = fileConfig.files.find(
      (item) => item.value === formData.definition,
    );

    setResult(
      <ProtoViewer
        protoString={proto}
        pbString={pbString}
        rootMessage={configObject.root}
      />,
    );
  };

  useEffect(() => {
    // check if select has a value
    if (formData.definition === '') return;

    // load the .proto file from the select value
    const url = '/protobuf/' + formData.definition;
    fetch(url)
      .then((response) => response.text())
      .then((text) => {
        setProto(text);
      });
  }, [formData]);

  return (
    <div className="view tools">
      <PanelTop
        left={<h1>{`${tGlobal('base.tools')}`}</h1>}
        navigation={<ToolsNavigation active="protobuf-reader" />}
      />
      <div className="area inner-content">
        <h2>{`${tYpsomed('tools.pbReader.title')}`}</h2>
        <p>{`${tYpsomed('tools.pbReader.intro')}`}</p>
        <div className="form">
          <Select
            label={tYpsomed('tools.pbReader.reader_select_proto')}
            values={config.files}
            handleChange={handleSelectChange}
          />
          <Input
            handleChange={handleFileChange}
            type="file"
            label={tYpsomed('tools.pbReader.reader_select_data')}
            name="data"
            infield={false}
          />
          <div className="flex v-center">
            <Button
              icon="settings"
              type="prominent"
              label={tYpsomed('tools.pbReader.reader_start')}
              action={loadProto}
            />
            <Button
              icon="delete"
              label={tYpsomed('tools.pbReader.reader_reset')}
              action={() => {
                setProto('');
                setResult(<></>);
                setError('');
              }}
            />
            {error !== '' ? <small className="error">{error}</small> : <></>}
          </div>
        </div>
        <div className="result mt-big">{result}</div>
      </div>
    </div>
  );
};
export default ToolsProtobufReader;
