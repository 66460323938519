import PrivateRoute from 'components/routes/private';
import PublicRoute from 'components/routes/public';
import React from 'react';
import Dashboard from 'views/dashboards/main';
import Login from 'views/shared/login';
import Profile from 'views/shared/profile';
import Reset from 'views/shared/reset';

const RoutesGeneral = () => {
  return (
    <>
      <PrivateRoute component={Dashboard} path="/" exact />
      <PrivateRoute component={Profile} path="/profile" exact />
      <PublicRoute restricted={true} component={Login} path="/login" exact />
      <PublicRoute
        restricted={false}
        component={Reset}
        path="/password-reset"
        exact
      />
    </>
  );
};
export default RoutesGeneral;
