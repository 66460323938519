import PanelTop from 'components/layout/panel-top';
import ToolsNavigation from 'components/navigations/detail/tools';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Tools = () => {
  const { t: tGlobal } = useTranslation('__global');
  const { t: tYpsomed } = useTranslation('__ypsomed');

  return (
    <div className="view tools">
      <PanelTop
        left={<h1>{`${tGlobal('base.tools')}`}</h1>}
        navigation={<ToolsNavigation active="overview" />}
      />
      <div className="inner-content">
        <p>{`${tYpsomed('tools.intro')}`}</p>
        <p>{`${tYpsomed('tools.contact')}`}</p>
      </div>
    </div>
  );
};
export default Tools;
