import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.js';

/**
 * Load CSS Files
 ***/
import './theme/reset.scss';
import './theme/base.scss';
import './theme/forms.scss';
import './theme/icons.scss';
import './theme/loader.scss';

ReactDOM.render(<App />, document.getElementById('app'));
