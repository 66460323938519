import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { createBrowserHistory } from 'history';
import { toast } from 'react-toastify';
import { getAxiosConfig, useProfile } from 'utils/index';
import Select from 'components/forms/select';
import PanelTop from 'components/layout/panel-top';
import ButtonBack from 'components/misc/button-back';
import ProfileForm from 'components/organisms/form-profile';

const history = createBrowserHistory({ forceRefresh: true });

const UsersCreate = () => {
  const [roles, updateRoles] = useState([]);
  const [selectedRole, updateSelectedRole] = useState('');
  const [errors, updateErrors] = useState([]);
  const { t } = useTranslation('__global');
  const [tScope, updateTScope] = useState('users');
  const profile = useProfile();

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(
        process.env.REACT_APP_API_URL + 'users/self',
        getAxiosConfig(),
      );
      if (result.data.role === 'role.hcp') {
        updateTScope('patients');
      }
    };
    fetchData();
  }, []);

  const handleSubmit = (fields) => (event) => {
    event.preventDefault();
    var data = {};
    data.role = selectedRole;
    for (var field in fields) {
      if (fields[field].value) {
        data[fields[field].key] = fields[field].value;
      } else {
        data[fields[field].key] = null;
      }
    }
    axios
      .post(
        process.env.REACT_APP_API_URL + 'users/create',
        data,
        getAxiosConfig(),
      )
      .then((response) => {
        if (response.data.status === 'E_USER_ALREADY_EXISTS') {
          updateErrors([]);
          toast.warn(t('error.E_USER_ALREADY_EXISTS'));
        }

        if (response.data.status === 'FORM_ERROR') {
          updateErrors(response.data.fields);
        }

        if (response.data.status === 'SUCCESS') {
          history.push('/' + tScope);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const roleChangeHandler = (e) => {
    updateSelectedRole(e.target.value);
  };

  useEffect(() => {
    const getRolesAsync = async () => {
      const result = await axios.get(
        process.env.REACT_APP_API_URL + 'users/roles',
        getAxiosConfig(),
      );
      updateRoles(result.data);
      updateSelectedRole(result.data[0]);
    };

    getRolesAsync();
  }, []);

  const roleSelectionField = (
    <Select
      handleChange={roleChangeHandler}
      name="role"
      label={t('profile.role')}
      values={roles.map((role) => {
        return {
          value: role,
          label: t(role),
        };
      })}
    />
  );

  return (
    <div className="view">
      <PanelTop
        loading={profile.loading}
        left={
          <>
            <ButtonBack />
            <h1>{t(tScope + '.create')}</h1>
          </>
        }
      />
      <div className="content">
        <ProfileForm
          handleSubmit={handleSubmit}
          additionalFields={roles.length > 1 ? roleSelectionField : false}
          errors={errors}
        />
      </div>
    </div>
  );
};

export default UsersCreate;
