import CardInjectionTechnical from 'components/cards/injection-technical';
import Editor from 'components/forms/editor';
import PanelTop from 'components/layout/panel-top';
import Button from 'components/misc/button';
import ButtonBack from 'components/misc/button-back';
import Modal from 'components/misc/modal';
import DeviceFactsListing from 'components/organisms/device-facts-listing';
import useDevice from 'hooks/device';
import { deviceDelete } from 'libs/device';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createBrowserHistory } from 'history';

import '../../theme/views/device.scss';
import DeviceFactsReusable from 'components/organisms/device-facts-reusable';
import LoadingIndicator from 'components/loaders/indicator';

const DeviceDetail = (props) => {
  const { t } = useTranslation('__ypsomed');
  const deviceId = props.computedMatch.params.id;
  const device = useDevice(deviceId);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  let pharma_prov_data;
  if (device.pharma_provisioning_json_data) {
    pharma_prov_data = JSON.parse(device.pharma_provisioning_json_data);
  }
  let pharma_setup_data;
  if (device.pharma_setup_json_data) {
    pharma_setup_data = JSON.parse(device.pharma_setup_json_data);
  }

  console.log(device);

  const closeModal = () => {
    setDeleteModalOpen(false);
  };

  const deleteConfirm = async () => {
    await deviceDelete(deviceId);
    let history = createBrowserHistory({ forceRefresh: true });
    history.push('/devices');
  };

  const deleteModalContent = (
    <div>
      <h3>{t('devices.delete_device')}</h3>
      <p>{device.external_device_id}</p>
      <div className="flex flex-space">
        <Button
          icon="trash"
          action={deleteConfirm}
          label={t('devices.delete_confirm')}
          type="prominent"
        />
        <Button
          action={closeModal}
          label={t('devices.delete_cancel')}
          icon="x"
        />
      </div>
    </div>
  );

  return (
    <div className="view device-detail">
      <Modal
        show={deleteModalOpen}
        onHide={closeModal}
        content={deleteModalContent}
      />
      <PanelTop
        left={
          <>
            <ButtonBack to="/devices" />
            <h1>{device.type}</h1>
          </>
        }
        right={
          <div>
            <Button
              action={() => {
                setDeleteModalOpen(true);
              }}
              label={t('devices.delete_device')}
              icon="trash"
            />
          </div>
        }
      />
      {device.loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <DeviceFactsListing deviceId={deviceId} />
          {device.type === 'YpsoMateReusable' ? (
            <DeviceFactsReusable device={device} />
          ) : (
            <></>
          )}
          <div className="setup-data">
            {pharma_prov_data || pharma_setup_data ? (
              <div className="setup-data">
                <h2>Setup data</h2>
                <Editor
                  readOnly={true}
                  value={JSON.stringify(pharma_prov_data, null, 4)}
                  label="Pharma Provisioning Data"
                  height="120px"
                />
                <Editor
                  readOnly={true}
                  value={JSON.stringify(pharma_setup_data, null, 4)}
                  label="Pharma Setup Data"
                  height="120px"
                />
              </div>
            ) : (
              <></>
            )}

            <hr />
            <div className="injection-archive">
              <h2>Injections</h2>
              {device.injection ? (
                device.injection.map((injection) => (
                  <CardInjectionTechnical key={injection.id} data={injection} />
                ))
              ) : (
                <p>No injections</p>
              )}
            </div>
            <div className="injection-archive">
              <h2>Archived Injections</h2>
              {device.injection_archive ? (
                device.injection_archive.map((injection) => (
                  <CardInjectionTechnical key={injection.id} data={injection} />
                ))
              ) : (
                <p>No archived injections</p>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default DeviceDetail;
