import axios from 'axios';
import LoadingIndicator from 'components/loaders/indicator';
import ButtonIcon from 'components/misc/button-icon';
import Modal from 'components/misc/modal';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAxiosConfig } from 'utils/index';

import '../../theme/components/card-diagnosis.scss';

const CardDiagnosis = ({ diagnosis, index, refresh }) => {
  const { t } = useTranslation('__global');
  const [showModal, setShowModal] = useState(false);
  const [treatments, setTreatments] = useState(false);

  const updateDelete = async (id) => {
    await axios.delete(
      process.env.REACT_APP_API_URL + '/treatment/archive/' + id,
      getAxiosConfig(),
    );
    loadDiagnosisData();
  };

  const deleteTreatment = (id) => {
    updateDelete(id);
  };

  const loadDiagnosisData = async () => {
    let _treatments = await axios.get(
      process.env.REACT_APP_API_URL +
        '/treatment/get/diagnosis/' +
        diagnosis.id,
      getAxiosConfig(),
    );
    _treatments = _treatments.data;
    setTreatments(_treatments);
  };

  const openModal = () => {
    loadDiagnosisData();
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setTreatments(false);
    if (refresh) {
      refresh();
    }
  };

  const renderModal = () => {
    return (
      <div className="dia-modal-content">
        <h3>{t('patients.diagnosis_information')}</h3>
        <div className="treatments">
          {treatments ? (
            treatments.map((treat, index) => (
              <div key={index} className="treatment card mt-base">
                <ButtonIcon
                  dataIndex={treat.id}
                  icon="trash"
                  action={(event) => {
                    console.log(event.target);
                    deleteTreatment(event.target.dataset.index);
                  }}
                />
                <h4 className="mt-0">
                  {t('patients.treatment')}
                  {treat.title ? <small>&nbsp;({treat.title})</small> : ''}
                </h4>
                <CardDiagnosisDates id={treat.id} />
              </div>
            ))
          ) : (
            <LoadingIndicator />
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        onClick={openModal}
        className={`card diagnosis ml-base col-s-${(index % 3) * 4 + 1} col-e-${
          (index % 3) * 4 + 5
        }`}>
        <small>{t('patients.diagnosis')}</small>
        <h4>{diagnosis.title}</h4>
      </div>
      <Modal show={showModal} content={renderModal()} onHide={closeModal} />
    </>
  );
};
export default CardDiagnosis;

const CardDiagnosisDates = ({ id }) => {
  const [dates, setDates] = useState(false);
  const now = new Date();

  const loadDates = async () => {
    let dates = await axios.get(
      process.env.REACT_APP_API_URL + '/event/get/treatment/' + id,
      getAxiosConfig(),
    );
    setDates(dates.data);
  };

  useEffect(() => {
    loadDates();
  }, []);

  return dates ? (
    <ul>
      {dates.map((date, index) => (
        <li
          key={index}
          className={`${
            now > new Date(date.start) ? 'date-past' : 'date-future'
          }`}>
          {new Date(date.start + 'Z').toLocaleString()}
        </li>
      ))}
    </ul>
  ) : (
    <LoadingIndicator />
  );
};
