import axios from 'axios';
import Input from 'components/forms/input';
import Select from 'components/forms/select';
import LoadingIndicator from 'components/loaders/indicator';
import Button from 'components/misc/button';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getAxiosConfig } from 'utils/index';
import SelectDate from '../../forms/select-date';

// eslint-disable-next-line no-unused-vars
const PatientCreateTreatment = ({ patient, afterSubmission = () => {} }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const { t } = useTranslation('__global');
  const [availableDiagnosis, setAvailableDiagnosis] = useState([]);
  const [availableDrugs, setAvailableDrugs] = useState([]);
  const [formValues, setFormValues] = useState({
    title: '',
  });
  const [dates, setDates] = useState([null]);
  const [pickers, setPickers] = useState([]);
  const handleSubmit = (e) => {
    setSubmitLoading(true);
    e.preventDefault();
    console.log(formValues);
    axios
      .post(
        process.env.REACT_APP_API_URL + 'treatment/create',
        {
          title: formValues.title,
          diagnosis: formValues.diagnosis,
          patient: patient,
          drug: formValues.drug,
        },
        getAxiosConfig(),
      )
      .then(async (response) => {
        if (response.data.status === 'SUCCESS') {
          let treatment_id = response.data.treatment;
          await dates.forEach((date) => {
            if (date === null) {
              return;
            }
            date = new Date(date);
            console.log(date.toJSON());
            axios
              .post(
                process.env.REACT_APP_API_URL + 'event/create',
                {
                  type: 'treatment',
                  relation_item: treatment_id,
                  start: date.toJSON(),
                  name: '',
                },
                getAxiosConfig(),
              )
              .then(() => {});
          });
          setSubmitLoading(false);
          toast.success(t('patients.treatment_added'));
          afterSubmission();
        }
      });
  };
  const onChange = (e) => {
    let val = { ...formValues };
    val[e.target.name] = e.target.value;
    setFormValues(val);
  };

  const updateDates = (date, index) => {
    let newDates = [...dates];
    newDates[index] = date;
    if (newDates[index] === null && newDates.length > 1) {
      newDates.splice(index, 1);
    }
    if (index === newDates.length - 1 && date !== null) {
      newDates.push(null);
    }
    setDates(newDates);
  };

  const loadData = async () => {
    setIsLoading(true);
    const dia_results = await axios.get(
      process.env.REACT_APP_API_URL + 'diagnosis/get/' + patient,
      getAxiosConfig(),
    );
    if (dia_results.data) {
      var fVals = { ...formValues };
      fVals.diagnosis = dia_results.data[0].id;
      setFormValues(fVals);
      setAvailableDiagnosis(dia_results.data);
    }

    const drug_results = await axios.get(
      process.env.REACT_APP_API_URL + 'drug/get',
      getAxiosConfig(),
    );
    if (drug_results.data) {
      drug_results.data.unshift({
        id: 0,
        name: 'None',
        type: false,
      });
      setAvailableDrugs(drug_results.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    const pickers = [];
    dates.forEach((date, index) => {
      pickers.push(
        <SelectDate
          startDate={date}
          key={index}
          onChange={(date) => {
            updateDates(date, index);
          }}
        />,
      );
    });
    setPickers(pickers);
  }, [dates]);

  return isLoading ? (
    <LoadingIndicator />
  ) : (
    <form className="grid" onSubmit={handleSubmit}>
      <h3 className="col-s-1 col-e-13">{t('patients.treatment_create')}</h3>
      <div className="col-s-1 col-e-13">
        <Input
          label={t('base.label')}
          value={formValues.title}
          name="title"
          handleChange={onChange}
        />
      </div>
      <div className="col-s-1 col-e-13">
        <Select
          handleChange={onChange}
          name="diagnosis"
          label={t('patients.diagnosis')}
          values={availableDiagnosis.map((diagnosis) => {
            return {
              value: diagnosis.id,
              label: diagnosis.title,
            };
          })}
        />
      </div>
      <div className="col-s-1 col-e-13">
        <Select
          handleChange={onChange}
          name="drug"
          label={t('patients.drugs_optional')}
          values={availableDrugs.map((drug) => {
            return {
              value: drug.id,
              label: drug.name + (drug.type ? ' (' + drug.type + ')' : ''),
            };
          })}
        />
      </div>
      <div className="col-s-1 col-e-13">
        <hr />
        <h4>{t('base.dates')}</h4>
      </div>
      <div className="col-s-1 col-e-13 date-pickers">{pickers}</div>
      <div className="col-s-7 col-e-13 flex-end">
        <Button
          label={t('base.create')}
          type="prominent"
          icon="arrow-right"
          loading={submitLoading}
        />
      </div>
    </form>
  );
};
export default PatientCreateTreatment;
