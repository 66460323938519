import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CircleLogo } from '../../assets/logo-circle-companion.svg';

import { logout, useProfile } from 'utils/index';
import NavigationAdmin from 'components/navigations/admin';
import NavigationPharma from 'components/navigations/pharma';
import NavigationPatient from 'components/navigations/patient';
import NavigationYpsomed from 'components/navigations/ypsomed';
import NavigationHCP from 'components/navigations/hcp';
import CardProfile from 'components/cards/profile';
import Icons from 'components/misc/icons';

import '../../theme/components/sidebar.scss';

const Sidebar = () => {
  let location = useLocation();
  const { t, i18n } = useTranslation('__global');
  const profile = useProfile();
  let activeLinkPosition = useActiveLinkPosition([location.pathname, profile]);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const languages = [
    {
      key: 'en-US',
      name: 'base.english',
    },
    {
      key: 'de-CH',
      name: 'base.german',
    },
  ];

  const getNavigation = (role) => {
    switch (role) {
      case 'role.root':
        return <NavigationAdmin />;
      case 'role.pharma':
        return <NavigationPharma />;
      case 'role.patient':
        return <NavigationPatient />;
      case 'role.ypsomed':
        return <NavigationYpsomed />;
      case 'role.hcp':
        return <NavigationHCP />;
      default:
        return <p>Navigation not found: {role}</p>;
    }
  };

  return (
    <div className="sidebar">
      <div
        className="active-bar"
        style={{
          top: activeLinkPosition.top,
          width: activeLinkPosition.width,
        }}></div>
      <div className="top-area">
        <div>
          <Link to="/" className="home">
            <CircleLogo />
          </Link>
        </div>

        <div className="navigation-panel">
          {!profile.loading ? <CardProfile profile={profile.data} /> : <></>}
          {!profile.loading ? getNavigation(profile.data.role) : <></>}
          <nav>
            <a href="#!" onClick={() => logout(true)}>
              <Icons show="power" />{' '}
              <span className="text">{t('base.signout')}</span>
            </a>
          </nav>
        </div>
      </div>
      <div className="bottom-bar">
        <div className="lang-sel">
          <small className="uppercase special block">
            {t('base.changelang')}
          </small>
          {languages.map((lang) => (
            <small
              key={lang.key}
              onClick={() => changeLanguage(lang.key)}
              className={`${i18n.language === lang.key ? 'active' : ''}`}>
              {t(lang.name)}
            </small>
          ))}
        </div>
      </div>
    </div>
  );
};

function useActiveLinkPosition(location_dependency) {
  const [activeLinkPosition, setActiveLinkPosition] = useState({
    top: 0,
    left: 0,
    width: 0,
  });

  const recalcPos = () => {
    var activeLink = document.querySelector('.sidebar nav a.active');
    var bar = document.querySelector('.sidebar .active-bar');
    var panelDemo = document.querySelector('.content > .panel-demo');
    var addMoveDown = 0;
    if (panelDemo) {
      addMoveDown = panelDemo.offsetHeight;
    }
    if (activeLink !== null) {
      setActiveLinkPosition({
        top: activeLink.getBoundingClientRect().top - addMoveDown,
        left: '-3px',
        width: activeLink.clientWidth,
      });
    } else {
      setActiveLinkPosition({
        top: bar.getBoundingClientRect().top - addMoveDown,
        left: '-100px',
        width: 0,
      });
    }
  };

  useEffect(() => {
    recalcPos();
    window.onresize = recalcPos;
  }, [...location_dependency]);

  return activeLinkPosition;
}

export default Sidebar;
