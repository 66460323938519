import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CardNotification from 'components/cards/notification';
import Info from 'components/misc/message-info';

import '../../theme/views/notifications.scss';
import ButtonIcon from 'components/misc/button-icon';

const Notifications = ({
  notificationList,
  readSingleClick,
  moreSingleClick,
  closeClick,
  isVisible,
  isInitial,
}) => {
  const { t } = useTranslation('__global');

  const keyDown = (event) => {
    // check if is escape key
    if (event.keyCode === 27) {
      closeClick();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', keyDown, false);

    return () => {
      document.removeEventListener('keydown', keyDown, false);
    };
  }, []);

  return (
    <div
      className={`notifications-container ${
        isVisible && !isInitial ? 'visible' : ''
      }`}>
      <div
        className={`notifications-view ${
          isVisible ? 'slide-in' : 'slide-out'
        } ${isInitial ? 'opacity-0' : ''}`}>
        <div>
          <div className="notifications-head flex splitted">
            <h2 className="">{t('notification.title')} </h2>
            <ButtonIcon icon="x" action={closeClick} />
          </div>
          <div className="notifications-list">
            {notificationList &&
            notificationList.status !== 'E_PERMISSION_MISSING' ? (
              notificationList.map((notification) => (
                <CardNotification
                  key={notification.id}
                  data={notification}
                  readClick={readSingleClick}
                  moreClick={moreSingleClick}
                />
              ))
            ) : (
              <Info message={t('notification.none')} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
