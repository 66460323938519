import Editor from 'components/forms/editor';
import Button from 'components/misc/button';
import React, { useEffect, useState } from 'react';
import { formatDate } from 'utils/index';
import { useTranslation } from 'react-i18next';

const systemStates = {
  0: 'Unspecified state (may be used for variable initialization)',
  1: 'ReadyForInjection',
  2: 'InvalidCassette',
  3: 'Idle',
  4: 'Injecting',
  5: 'Holding',
  6: 'InjectionFailed',
  7: 'RemoveFromSkin',
  8: 'InjectionComplete',
  9: 'InjectionIncomplete',
  10: 'CassetteInserted',
  11: 'CassetteRemoved',
  12: 'ChargingBattery',
  13: 'ChargingComplete',
  14: 'Standby',
  15: 'Abort',
  16: '(may be used for boundry checks)',
};

const lipoStates = {
  0: 'Unspecified',
  1: 'The battery is fully charged',
  2: 'The battery charge condition is within its normal range',
  3: 'The battery is in low charge condition',
  4: 'The battery is empty',
  5: 'he battery is in critically low charge condition',
  6: 'LastEnum',
};

const DeviceFactsReusable = ({ device }) => {
  const { t } = useTranslation('__global');
  const [showStatusHistory, setShowStatusHistory] = useState(false);
  const [statusHistory, setStatusHistory] = useState([]);
  const [deviceLog, setDeviceLog] = useState([]);
  const [showDeviceLog, setShowDeviceLog] = useState(false);
  const [status, setStatus] = useState({
    firmwareVersion: '?',
    settingsVersion: '?',
    injectionVolume: 0,
    systemState: '?',
    lipoState: '?',
    original: '?',
    date: '?',
    containerTimestamp: '?',
  });

  useEffect(() => {
    // check if device has property "ymre" and set data accordingly

    if (!device.ymre) {
      return;
    }

    if (device.ymre.state === null && device.ymre.state_history === null) {
      return;
    }

    setStatus({
      firmwareVersion: device.ymre.state.firmwareVersion,
      settingsVersion: device.ymre.state.settingsVersion,
      injectionVolume: device.ymre.state.injectionVolume,
      systemState:
        device.ymre.state.systemState +
        ' (' +
        systemStates[device.ymre.state.systemState] +
        ')',
      lipoState:
        device.ymre.state.lipoState +
        ' (' +
        lipoStates[device.ymre.state.lipoState] +
        ')',
      original: device.ymre.state.original,
      date: formatDate(new Date(device.ymre.state.created * 1000)),
      containerTimestamp: formatDate(
        new Date(device.ymre.state.containerTimestamp * 1000),
      ),
    });

    // loop through history and set data accordingly
    const history = [];
    device.ymre.state_history.forEach((item) => {
      history.push(item);
    });
    setStatusHistory(history);
  }, [device]);

  useEffect(() => {
    if (!device.ymre) return;

    if (!device.ymre.devicelog) return;

    // set device log
    setDeviceLog(device.ymre.devicelog);
  }, [device]);

  return (
    <div className="ymre-data">
      <div className="current-status">
        <h2>{`${t('ymre.latest_status_update')}`}</h2>
        <dl>
          <div>
            <dt>{`${t('ymre.firmware_version')}`}</dt>
            <dd>{status.firmwareVersion}</dd>
          </div>
          <div>
            <dt>{`${t('ymre.settings_version')}`}</dt>
            <dd>{status.settingsVersion}</dd>
          </div>
          <div>
            <dt>{`${t('ymre.system_state')}`}</dt>
            <dd>{status.systemState}</dd>
          </div>
          <div>
            <dt>{`${t('ymre.lipo_state')}`}</dt>
            <dd>{status.lipoState}</dd>
          </div>
          <div>
            <dt>{`${t('ymre.injection_volume')}`}</dt>
            <dd>{status.injectionVolume}</dd>
          </div>
          <div>
            <dt>{`${t('ymre.updated')}`}</dt>
            <dd>{status.date}</dd>
          </div>
          <div>
            <dt>{`${t('ymre.container_timestamp')}`}</dt>
            <dd>{status.containerTimestamp}</dd>
          </div>
        </dl>
        <div className="original">
          <h3>{`${t('ymre.original_data')}`}</h3>
          <pre>{status.original}</pre>
        </div>
        {statusHistory.length > 0 && (
          <div className="status-history">
            <h2>{`${t('ymre.status_history')}`}</h2>
            <Button
              icon={showStatusHistory ? 'minus-circle' : 'plus-circle'}
              label={
                showStatusHistory
                  ? t('ymre.hide_status_history')
                  : t('ymre.show_status_history')
              }
              action={() => {
                setShowStatusHistory(!showStatusHistory);
              }}
            />
            {showStatusHistory && (
              <div className="history">
                {statusHistory.map((item, index) => (
                  <Editor
                    key={index}
                    value={JSON.stringify(item, null, 4)}
                    label=""
                    readOnly={true}
                    height={300}
                  />
                ))}
              </div>
            )}
          </div>
        )}
        {deviceLog.length > 0 && (
          <div className="status-history">
            <h2>{`${t('ymre.device_log')}`}</h2>
            <Button
              icon={showDeviceLog ? 'minus-circle' : 'plus-circle'}
              label={
                showDeviceLog
                  ? t('ymre.hide_device_log')
                  : t('ymre.show_device_log')
              }
              action={() => {
                setShowDeviceLog(!showDeviceLog);
              }}
            />
            {showDeviceLog && (
              <div className="history">
                {deviceLog.map((item, index) => (
                  <Editor
                    key={index}
                    value={JSON.stringify(item, null, 4)}
                    label=""
                    readOnly={true}
                    height={300}
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DeviceFactsReusable;
