import { useEffect, useState } from 'react';
import axios from 'axios';
import { getAxiosConfig } from 'utils/index';

const useDrugs = (props = { pharma_id: false }) => {
  const [drugs, setDrugs] = useState([]);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (mounted) {
      return;
    }

    const fetchDrugs = async () => {
      let drugs = await axios.get(
        process.env.REACT_APP_API_URL + '/drug/get',
        getAxiosConfig(),
      );
      if (props.pharma_id) {
        let newDrugs = [];
        drugs.data.forEach((value) => {
          if (parseInt(value.pharma_id, 10) === parseInt(props.pharma_id, 10)) {
            newDrugs.push(value);
          }
        });
        setDrugs(newDrugs);
      } else {
        setDrugs(drugs.data);
      }
    };
    fetchDrugs();

    return () => {
      setMounted(false);
    };
  }, []);

  return drugs;
};
export default useDrugs;
