import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const PatientNavigation = ({ active = 'overview', item = false }) => {
  const { t } = useTranslation('__global');
  if (!item) return <div></div>;

  const base = '/patients/' + item;
  const items = [
    {
      id: 'overview',
      name: t('patients.overview'),
      link: base + '/',
    },
    {
      id: 'therapy',
      name: t('patients.therapy_plan'),
      link: base + '/therapy',
    },
    {
      id: 'anamnesis_history',
      name: t('patients.anamnesis_history'),
      link: base + '/anamnesis',
    },
    {
      id: 'personal_information',
      name: t('patients.personal_information'),
      link: base + '/personal_information',
    },
  ];
  return (
    <div className="subnavigation">
      {items.map((item, index) => (
        <Link
          className={active === item.id ? 'active' : ''}
          to={item.link}
          key={index}>
          {item.name}
        </Link>
      ))}
    </div>
  );
};
export default PatientNavigation;
