import React from 'react';

import '../../theme/components/splitline.scss';

const Splitline = ({
  type = 'horizontal',
  spacing = 'default',
  mt = 'default',
  mb = 'default',
  height = 'default',
}) => {
  let classes = `splitline ${type} space-${spacing} margin-top-${mt} margin-bottom-${mb} height-${height}`;
  return <div className={classes}></div>;
};
export default Splitline;
