import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../theme/forms.scss';
import Icons from '../misc/icons';
import '../../theme/components/select-date.scss';

const SelectDate = ({
  startDate = '',
  onChange = () => {},
  id = false,
  withTime = true,
  icon = 'calendar',
}) => {
  if (!id) {
    id = Math.floor(Math.random() * 10);
  }

  return (
    <div className="select-date">
      <DatePicker
        id={`picker-${id}`}
        selected={startDate}
        onChange={(date, event) => onChange(date, event)}
        showTimeSelect={withTime}
        calendarStartDay={1}
        autoComplete="off"
        dateFormat="dd.MM.yyyy HH:mm"
        timeFormat="HH:mm"
      />
      <Icons show={icon} />
    </div>
  );
};
export default SelectDate;
