import CardDeviceType from 'components/cards/device-type';
import Select from 'components/forms/select';
import PanelTop from 'components/layout/panel-top';
import LoadingIndicator from 'components/loaders/indicator';
import ButtonIcon from 'components/misc/button-icon';
import Icons from 'components/misc/icons';
import Table from 'components/table/table';
import TableRow from 'components/table/table-row';
import TableZapper from 'components/table/table-zapper';
import useDevices from 'hooks/devices';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMoments } from 'utils/dates';

import '../../theme/views/pharma/devices.scss';

const Devices = () => {
  const { t } = useTranslation('__pharma');
  const dater = useMoments();
  const itemsPerPage = 20;
  const [page, setPage] = useState(1);
  const [deviceParams, setDeviceParams] = useState({
    limit: itemsPerPage,
    offset: itemsPerPage * (page - 1),
    status: '',
  });
  const devices = useDevices(deviceParams);

  const updateStatusFilter = (evt) => {
    const newParams = { ...deviceParams };
    setPage(1);
    newParams.status = evt.target.value;
    setDeviceParams(newParams);
  };

  useEffect(() => {
    const newParams = { ...deviceParams };
    newParams.offset = itemsPerPage * (page - 1);
    setDeviceParams(newParams);
  }, [page]);

  const zapper = (
    <TableZapper
      total={devices.total}
      page={page}
      pagesize={itemsPerPage}
      directAction={(target) => {
        setPage(target);
      }}
      nextAction={() => {
        var newPage = parseInt(page, 10) + 1;
        setPage(newPage);
      }}
      prevAction={() => {
        var newPage = parseInt(page, 10) - 1;
        setPage(newPage);
      }}
    />
  );

  return (
    <div className="view pharma">
      <PanelTop left={<h1>{t('devices.title')}</h1>} right={<></>} />
      <div className="filters collapse-to-top">
        <Select
          name="device-status-filter"
          label={false}
          handleChange={updateStatusFilter}
          values={[
            {
              label: t('devices.filter.status_all'),
              value: 'all',
            },
            {
              label: t('devices.filter.status_used'),
              value: 'used',
            },
            {
              label: t('devices.filter.status_available'),
              value: 'available',
            },
          ]}
        />
      </div>
      <div className="table">
        {devices ? (
          <>
            <Table
              loading={devices.loading}
              before={zapper}
              head={
                <TableRow
                  type="th"
                  data={[
                    t('devices.table.h_type'),
                    t('devices.table.h_date'),
                    t('devices.table.h_id'),
                    '',
                  ]}
                />
              }
              body={devices.items.map((device) => (
                <TableRow
                  key={device.id}
                  data={[
                    <CardDeviceType key={device.id} type={device.type} />,
                    device.injection ? (
                      <div className="inj-column">
                        <div className="injection-date">
                          {device.injection[0].validation == 'CHALLENGE' ? (
                            <Icons show="alert-triangle" />
                          ) : (
                            <Icons show="check" />
                          )}
                        </div>
                        <span>
                          {dater(device.injection[0].date + 'Z').format('l')}{' '}
                          {dater(device.injection[0].date + 'Z').format('LTS')}
                        </span>
                      </div>
                    ) : (
                      <div className="no-injection">
                        <Icons show="help-circle" />
                      </div>
                    ),
                    <code key={device.id}>{device.external_device_id}</code>,
                    <ButtonIcon
                      key={device.id}
                      icon="arrow-right"
                      link={`/device/${device.id}`}
                    />,
                  ]}
                />
              ))}
              footer={zapper}
            />
          </>
        ) : (
          <LoadingIndicator />
        )}
      </div>
    </div>
  );
};
export default Devices;
