import React, { useEffect, useState } from 'react';

import '../../theme/components/card-device-type.scss';

import device_ymon from '../../assets/devices/device-ypsomate-on.png';
import device_ymre from '../../assets/devices/device-ypsomate-reusable.png';
import device_unknown from '../../assets/devices/device-unknown.png';
import { Link } from 'react-router-dom';

const CardDeviceType = ({ type, link = 'undefined', display = 'default' }) => {
  const [title, setTitle] = useState(type);
  const [image, setImage] = useState(<></>);

  useEffect(() => {
    switch (type) {
      case 'YpsoMateOn':
        setTitle('YpsoMate On');
        setImage(device_ymon);
        break;

      case 'YpsoMateReusable':
        setTitle('Reusable Autoinjector');
        setImage(device_ymre);
        break;

      default:
        setImage(device_unknown);
        break;
    }
  }, [type]);

  return (
    <div className={`card-device-type ${display}`}>
      {link !== 'undefined' ? (
        <Link to={link}>
          <div className="image">
            <img src={image} />
          </div>
          <strong>{title}</strong>
        </Link>
      ) : (
        <>
          <div className="image">
            <img src={image} />
          </div>
          <strong>{title}</strong>
        </>
      )}
    </div>
  );
};

export default CardDeviceType;
