import Icons from 'components/misc/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import '../../theme/components/panel-demo.scss';

const PanelDemo = () => {
  const { t } = useTranslation('__global');
  const [fadeOut, setFadeOut] = useState(false);
  const [show, setShow] = useState(false);

  const hideWarning = () => {
    setFadeOut(true);
    localStorage.setItem('hide_clinical_warning', '1');
    setTimeout(() => {
      setShow(false);
    }, 2000);
  };

  useEffect(() => {
    // get initial state from localstorage
    if (!localStorage.getItem('hide_clinical_warning')) {
      setShow(true);
    }
  }, []);

  return show ? (
    <div className={`panel-demo ${fadeOut ? 'hide' : ''}`}>
      <div className="left">
        <small>{t('base.demo_text')}</small>
      </div>
      <div className="right">
        <a href="#" onClick={hideWarning} className="no-decoration">
          <Icons show="x" />
        </a>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default PanelDemo;
