import i18n from 'components/misc/i18n';
import moment from 'moment';

import 'moment/locale/de';
import 'moment/locale/fr';

export const useMoments = () => {
  moment.locale(i18n.language);
  return moment;
};
