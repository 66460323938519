import CardDeviceType from 'components/cards/device-type';
import Select from 'components/forms/select';
import PanelTop from 'components/layout/panel-top';
import LoadingIndicator from 'components/loaders/indicator';
import Button from 'components/misc/button';
import ButtonIcon from 'components/misc/button-icon';
import Icons from 'components/misc/icons';
import Modal from 'components/misc/modal';
import Tag from 'components/misc/tag';
import Table from 'components/table/table';
import TableRow from 'components/table/table-row';
import TableZapper from 'components/table/table-zapper';
import useDevices from 'hooks/devices';
import useDrugs from 'hooks/drugs';
import useUsers from 'hooks/users';
import { injectionArchiveByDrugIdentifier } from 'libs/injection';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import '../../theme/views/pharma/devices.scss';
import DevicesAddManually from './modals/devicesAddManually';

const voltageWarningThreshold = 2.2;

const deviceTypes = [
  {
    label: 'All Device Types',
    value: '',
  },
  {
    label: 'YpsoMateOn',
    value: 'YpsoMateOn',
  },
  {
    label: 'YpsoMateReusable',
    value: 'YpsoMateReusable',
  },
];

const Devices = () => {
  const { t } = useTranslation('__ypsomed');
  const users = useUsers('role.pharma');
  const itemsPerPage = 20;
  const [page, setPage] = useState(1);
  const [deviceParams, setDeviceParams] = useState({
    limit: itemsPerPage,
    offset: itemsPerPage * (page - 1),
    status: '',
    user: '',
  });
  const devices = useDevices(deviceParams);
  const [__modalContent, __setModalContent] = useState(null);
  const [__modalContentElement, __setModalContentElement] = useState(
    'No Content for Modal',
  );
  const [__showModal, __setShowModal] = useState(false);
  const drugs = useDrugs();
  const [drugsToReset, setDrugsToReset] = useState([]);
  const [userSelect, setUserSelect] = useState([]);

  const updateFilter = (evt) => {
    setPage(1);
    const newParams = { ...deviceParams };
    if (evt.target.name === 'device-status-filter') {
      newParams.status = evt.target.value;
    }
    if (evt.target.name === 'device-user-filter') {
      newParams.user = evt.target.value;
    }
    if (evt.target.name === 'device-type-filter') {
      newParams.type = evt.target.value;
    }
    setDeviceParams(() => {
      return newParams;
    });
  };

  useEffect(() => {
    switch (__modalContent) {
      case 'add':
        __setModalContentElement(__renderModalAddDevice());
        break;
      case 'reset':
        __setModalContentElement(__renderModalResetInjections());
        break;
      default:
        __setModalContentElement('No Content for Modal');
    }
  }, [__modalContent]);

  useEffect(() => {
    const newParams = { ...deviceParams };
    newParams.offset = itemsPerPage * (page - 1);
    setDeviceParams(newParams);
  }, [page]);

  useEffect(() => {
    let userList = [
      {
        label: 'All Pharma Users',
        value: '',
      },
    ];
    users.forEach((user) => {
      userList.push({
        label: user.forename + ' ' + user.lastname,
        value: user.id,
      });
    });
    setUserSelect(userList);
  }, [users]);

  const zapper = (
    <TableZapper
      total={devices.total}
      page={page}
      pagesize={itemsPerPage}
      directAction={(target) => {
        setPage(target);
      }}
      nextAction={() => {
        var newPage = parseInt(page, 10) + 1;
        setPage(newPage);
      }}
      prevAction={() => {
        var newPage = parseInt(page, 10) - 1;
        setPage(newPage);
      }}
    />
  );

  const __closeModal = () => {
    __setShowModal(false);
    window.dispatchEvent(new Event('initUpdateData'));
  };

  const __setDrugsToReset = (e) => {
    let current = [...drugsToReset];
    if (!e.target.checked) {
      const index = current.indexOf(e.target.id);
      if (index > -1) {
        current.splice(index, 1);
      }
    }
    if (!(e.target.id in current) && e.target.checked) {
      current.push(e.target.id);
    }
    setDrugsToReset(current);
  };

  const __renderModalAddDevice = () => {
    return <DevicesAddManually />;
  };

  const __renderModalResetInjections = () => {
    return (
      <div className="modal-content reset-modal">
        <h3>{t('devices.resetModal.title')}</h3>
        <ul>
          {drugs.map((drug) => (
            <li key={drug.id}>
              <input
                name="reset-drug-injections"
                type="checkbox"
                onChange={__setDrugsToReset}
                id={`check-drug--${drug.identifier}`}
              />{' '}
              <label htmlFor={`check-drug--${drug.identifier}`}>
                {drug.id} - {drug.identifier} / {drug.name}
              </label>
            </li>
          ))}
        </ul>
        <Button
          action={async () => {
            for (var index = 0; index < drugsToReset.length; index++) {
              let drug = drugsToReset[index];
              await injectionArchiveByDrugIdentifier(
                drug.substring(drug.indexOf('--') + 2),
              );
            }
            __closeModal();
            window.dispatchEvent(new Event('initUpdateData'));
          }}
          label={t('devices.reset_injections_label')}
          icon="trash"
        />
      </div>
    );
  };

  return (
    <div className="view pharma">
      <Modal
        show={__showModal}
        content={__modalContentElement}
        onHide={__closeModal}
      />
      <PanelTop
        left={<h1>{t('devices.title')}</h1>}
        right={
          <>
            <Button
              action={() => {
                __setShowModal(true);
                __setModalContent('reset');
              }}
              label={t('devices.reset_injections_label')}
              icon="trash"
            />
            <Button
              action={() => {
                __setModalContent('add');
                __setShowModal(true);
              }}
              type="prominent"
              label={t('devices.add_device')}
              icon="file-plus"
            />
          </>
        }
      />
      <div className="filters collapse-to-top">
        <Select
          name="device-status-filter"
          label={false}
          handleChange={updateFilter}
          values={[
            {
              label: t('devices.filter.status_all'),
              value: 'all',
            },
            {
              label: t('devices.filter.status_used'),
              value: 'used',
            },
            {
              label: t('devices.filter.status_available'),
              value: 'available',
            },
          ]}
        />
        <Select
          name="device-user-filter"
          label={false}
          handleChange={updateFilter}
          values={userSelect}
        />
        <Select
          name="device-type-filter"
          label={false}
          handleChange={updateFilter}
          values={deviceTypes}
        />
      </div>
      <div className="table">
        {devices ? (
          <>
            <Table
              loading={devices.loading}
              before={zapper}
              head={
                <TableRow
                  type="th"
                  data={[
                    t('devices.table.h_type'),
                    t('devices.table.h_id'),
                    t('devices.table.h_date'),
                    t('devices.table.h_duration'),
                    t('devices.table.h_voltage'),
                    '',
                  ]}
                />
              }
              body={devices.items.map((device) => (
                <TableRow
                  key={device.id}
                  data={[
                    <CardDeviceType
                      key={device.id}
                      type={device.type}
                      link={`/device/${device.id}`}
                    />,
                    <code key={device.id}>{device.external_device_id}</code>,
                    device.injection ? (
                      <div className="inj-column">
                        <div className="injection-date">
                          <Icons show="check" />
                        </div>
                        <span>{device.injection[0].date}</span>
                      </div>
                    ) : (
                      <div className="no-injection">
                        <Icons show="help-circle" />
                      </div>
                    ),
                    device.injection ? (
                      <span>
                        {device.injection[0].data.device_data &&
                        device.injection[0].data.device_data?.decoded ? (
                          (
                            device.injection[0].data.device_data.decoded
                              .time_ms / 1000
                          ).toFixed(2) + 's'
                        ) : (
                          <></>
                        )}
                      </span>
                    ) : (
                      <></>
                    ),
                    device.injection ? (
                      <span>
                        {device.injection[0].data.device_data?.decoded &&
                        device.injection[0].data.device_data.decoded.voltage ? (
                          (
                            device.injection[0].data.device_data.decoded
                              .voltage / 1000
                          ).toFixed(2) + 'V'
                        ) : (
                          <></>
                        )}
                        {device.injection[0].data.device_data?.decoded &&
                        (
                          device.injection[0].data.device_data.decoded.voltage /
                          1000
                        ).toFixed(2) <= voltageWarningThreshold ? (
                          <span className="ml-small">
                            <Tag
                              value={t('devices.low_voltage')}
                              type="error"
                            />
                          </span>
                        ) : (
                          <></>
                        )}
                      </span>
                    ) : (
                      <></>
                    ),
                    <ButtonIcon
                      key={device.id}
                      icon="arrow-right"
                      link={`/device/${device.id}`}
                    />,
                  ]}
                />
              ))}
              footer={zapper}
            />
          </>
        ) : (
          <LoadingIndicator />
        )}
      </div>
    </div>
  );
};
export default Devices;
