//not sure if this is needed yet

import { useEffect, useState } from 'react';
import axios from 'axios';
import { getAxiosConfig } from 'utils/index';

const useInjections = (props = { pharma_id: false, limit: false }) => {
  const [items, setInjections] = useState([]);
  const [mounted, setMounted] = useState(false);

  var url =
    process.env.REACT_APP_API_URL + '/injection/get/user/' + props.pharma_id;
  if (props.limit !== false) {
    url = url + '?limit=' + props.limit;
  }
  useEffect(() => {
    if (mounted) {
      return;
    }

    const fetchInjections = async () => {
      let items = await axios.get(url, getAxiosConfig());
      setInjections(items.data);
    };
    fetchInjections();

    return () => {
      setMounted(false);
    };
  }, []);

  return items;
};
export default useInjections;
