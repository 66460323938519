import React, { useState } from 'react';
import { createBrowserHistory } from 'history';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Input from 'components/forms/input';
import Button from 'components/misc/button';
import { isLogin, logout } from 'utils/index';
import { ReactComponent as Logo } from 'assets/logo-companion.svg';

const Reset = () => {
  const { t } = useTranslation('__global');
  const [dataset, updateDataset] = useState({
    data: {
      password: '',
      password_check: '',
    },
    errors: {
      password: false,
    },
  });

  if (isLogin()) {
    let link = window.location.pathname + window.location.search;
    logout(true, link);
  }

  const handleChange = (event) => {
    const fieldToUpdate = event.target.name;
    const newData = dataset.data;
    const oldErrors = dataset.errors;
    newData[fieldToUpdate] = event.target.value;
    updateDataset({
      data: newData,
      errors: oldErrors,
    });
  };
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post(process.env.REACT_APP_API_URL + 'users/set-password', {
        token: token,
        password: dataset.data.password,
        password_check: dataset.data.password_check,
      })
      .then((response) => {
        if (response.data.status === 'SUCCESS') {
          toast.success('pwset');
          let history = createBrowserHistory({ forceRefresh: true });
          history.push('/login');
        } else {
          const newData = Object.assign({}, dataset);
          newData.errors.password = t('error.' + response.data.status);
          updateDataset(newData);
        }
      });
  };

  return (
    <div className="login-frame w100 bg-grey full-height">
      <div className="container">
        <div className="top">
          <Logo />
        </div>
        <div className="bottom">
          <div className="form">
            <form className="login" onSubmit={handleSubmit}>
              <h1>{t('pw_reset.title')}</h1>
              <Input
                name="password"
                icon="lock"
                label={t('base.password')}
                value={dataset.data.password}
                type="password"
                handleChange={handleChange}
                error={dataset.errors.password}
              />
              <Input
                name="password_check"
                icon="lock"
                label={t('pw_reset.password_repeat')}
                value={dataset.data.password_check}
                type="password"
                handleChange={handleChange}
              />
              <div className="form-element flex-end">
                <Button
                  label={t('pw_reset.set_password')}
                  type="prominent"
                  icon="arrow-right"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reset;
