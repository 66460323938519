import React from 'react';

import { ReactComponent as IllustrationTyping } from '../../assets/illustrations/typing.svg';
import { ReactComponent as IllustrationNotFound } from '../../assets/illustrations/not-found.svg';
import { ReactComponent as IllustrationQRScan } from '../../assets/illustrations/qr-scan.svg';

import '../../theme/components/box-message.scss';

const BoxMessage = ({ primary = false, secondary = false }) => {
  if (primary && !primary.illustration) {
    primary.illustration = 'notfound';
  }
  if (secondary && !secondary.illustration) {
    secondary.illustration = 'notfound';
  }
  var illustrations = {
    typing: <IllustrationTyping />,
    qr: <IllustrationQRScan />,
    notfound: <IllustrationNotFound />,
  };

  return (
    <div className="message-box">
      {primary ? (
        <div className="primary">
          <div className="main">
            {!primary.hideIllustration ? (
              <div className="illustration">
                {illustrations[primary.illustration]}
              </div>
            ) : (
              ''
            )}
            <h3>{primary.title}</h3>
            <p>{primary.text}</p>
          </div>
          {primary.more ? <div className="more">{primary.more}</div> : ''}
        </div>
      ) : (
        ''
      )}
      {secondary ? (
        <div className="secondary">
          <div className="main">
            {!secondary.hideIllustration ? (
              <div className="illustration">
                {illustrations[secondary.illustration]}
              </div>
            ) : (
              ''
            )}
            <h3>{secondary.title}</h3>
            <p>{secondary.text}</p>
          </div>
          {secondary.more ? <div className="more">{secondary.more}</div> : ''}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
export default BoxMessage;
