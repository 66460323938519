import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translation_deCH from '../../locales/de-CH.json';
import translation_enUS from '../../locales/en-US.json';

import translation_pharma_deCH from '../../locales/de-CH.pharma.json';
import translation_pharma_enUS from '../../locales/en-US.pharma.json';

import translation_ypsomed_deCH from '../../locales/de-CH.ypsomed.json';
import translation_ypsomed_enUS from '../../locales/en-US.ypsomed.json';

import translation_urls_deCH from '../../locales/de-CH.urls.json';
import translation_urls_enUS from '../../locales/en-US.urls.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },
    fallbackLng: 'en-US',
    //debug: true,
    resources: {
      'en-US': {
        __global: translation_enUS,
        __pharma: translation_pharma_enUS,
        __ypsomed: translation_ypsomed_enUS,
        __urls: translation_urls_enUS,
      },
      'de-CH': {
        __global: translation_deCH,
        __pharma: translation_pharma_deCH,
        __ypsomed: translation_ypsomed_deCH,
        __urls: translation_urls_deCH,
      },
    },
  });

export default i18n;
