import { useEffect, useState } from 'react';
import axios from 'axios';
import { getAxiosConfig } from 'utils/index';

const useDevices = (
  attr = { limit: false, offset: false, status: '', user: '', type: '' },
) => {
  const [updateCounter, setUpdateCounter] = useState(1);
  const [devices, setDevices] = useState({
    amount: 0,
    loading: true,
    items: [],
  });

  useEffect(() => {
    window.addEventListener('initUpdateData', () => {
      setUpdateCounter(updateCounter + 1);
    });
  }, []);

  useEffect(() => {
    const result = { ...devices };
    result.loading = true;
    setDevices(result);

    const params = {};
    if (attr.limit) {
      params.limit = attr.limit;
    }

    if (attr.offset) {
      params.offset = attr.offset;
    }

    if (attr.status !== '') {
      params.status = attr.status;
    }

    if (attr.user !== '') {
      params.user = attr.user;
    }

    if (attr.type !== '') {
      params.type = attr.type;
    }

    console.log(params);

    const fetchDevices = async () => {
      let devices = await axios.get(process.env.REACT_APP_API_URL + 'devices', {
        params: params,
        ...getAxiosConfig(),
      });
      const result = { ...devices.data };
      result.loading = false;
      setDevices(result);
    };
    fetchDevices();
  }, [
    attr.offset,
    attr.limit,
    attr.status,
    attr.user,
    attr.type,
    updateCounter,
  ]);

  return devices;
};
export default useDevices;
