import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getAxiosConfig, useProfile } from 'utils/index';
import PanelTop from 'components/layout/panel-top';
import ButtonBack from 'components/misc/button-back';
import PatientTopActions from './patients-detail-top-actions';
import PatientNavigation from 'components/navigations/detail/patient';
import LoadingIndicator from 'components/loaders/indicator';
import CardDiagnosis from 'components/cards/diagnosis';
import Select from 'components/forms/select';
import useDrugs from 'hooks/drugs';

const PatientsDetail = (props) => {
  const [userData, updateUserData] = useState({
    data: false,
    loading: true,
  });
  const [diagnosis, updateDiagnosis] = useState([]);
  const [defaultDrugValues, setDefaultDrugValues] = useState([]);
  const { t } = useTranslation('__global');
  const userId = props.computedMatch.params.userId;
  const profile = useProfile();
  const drugs = useDrugs();

  const getUserDataAsync = async () => {
    const result = await axios.get(
      process.env.REACT_APP_API_URL + 'users/get/' + userId,
      getAxiosConfig(),
    );
    if (result.data.status !== 'E_REQUEST_UNRELATED_USER') {
      const data = result.data;
      updateUserData({
        data: data,
        loading: false,
      });
    } else {
      toast.warn(result.data.status);
    }

    const dia_results = await axios.get(
      process.env.REACT_APP_API_URL + '/diagnosis/get/' + userId,
      getAxiosConfig(),
    );
    if (dia_results.data) {
      updateDiagnosis(dia_results.data);
    }
  };

  const updateDefaultDrug = async (event) => {
    const result = await axios.post(
      process.env.REACT_APP_API_URL + '/users/set-default-drug',
      {
        user: userId,
        identifier: event.target.value,
      },
      getAxiosConfig(),
    );
    if (result.data.status == 'SUCCESS') {
      toast.success(t('patients.default_drug.success'));
    }
  };

  useEffect(() => {
    let drugsForSelect = [];
    drugs.forEach((value) => {
      drugsForSelect[value.id] = {
        value: value.identifier,
        label: value.identifier + ' - ' + value.name,
      };
    });
    setDefaultDrugValues(drugsForSelect);
  }, [drugs]);

  // get user information
  useEffect(() => {
    getUserDataAsync();
  }, [userId]);

  return (
    <div className="view">
      <PanelTop
        loading={profile.loading}
        left={
          <>
            <ButtonBack to="/patients" />
            <h1>
              {!profile.loading ? (
                profile.data.role === 'role.hcp' ? (
                  <>
                    {userData.data.forename} {userData.data.lastname}{' '}
                  </>
                ) : (
                  <>
                    {t('base.manage')}{' '}
                    <span className="fade">{userData.data.email}</span>
                  </>
                )
              ) : (
                '..'
              )}
            </h1>
          </>
        }
        right={
          <PatientTopActions
            patient={userData.data.id}
            refresh={getUserDataAsync}
          />
        }
        navigation={
          <PatientNavigation item={userData.data.id} active="overview" />
        }
      />
      {userData.loading ? (
        <div className="content">
          <LoadingIndicator />
        </div>
      ) : (
        <div className="content">
          <div className="base">
            <dl>
              <dt>{t('patients.lastname_forename')}</dt>
              <dd>
                {userData.data.lastname} {userData.data.forename}
              </dd>
              <dt>{t('profile.street')}</dt>
              <dd>
                {userData.data.street} {userData.data.street_no}
              </dd>
              <dt>{t('base.email')}</dt>
              <dd>{userData.data.email}</dd>
            </dl>
          </div>
          <div className="diagnosis grid ml-base--1 mt-medium">
            {diagnosis.map((dia, index) => (
              <CardDiagnosis
                key={index}
                diagnosis={dia}
                index={index}
                refresh={getUserDataAsync}
              />
            ))}
          </div>
          <div className="default-drug card mt-medium">
            <h4 className="mt-0">{t('patients.default_drug.title')}</h4>
            <p className="mt-0">{t('patients.default_drug.description')}</p>
            <Select
              label={t('patients.default_drug.label')}
              name="default-drug"
              values={defaultDrugValues}
              handleChange={updateDefaultDrug}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PatientsDetail;
