import React, { useState, useEffect } from 'react';
import protobuf from 'protobufjs';

function ProtoViewer({ protoString, pbString, rootMessage }) {
  const [payload, setPayload] = useState(null);

  useEffect(() => {
    const root = protobuf.parse(protoString).root;

    // Look up the LogDataMessage type
    const Data = root.lookupType(rootMessage);

    // Decode the payload
    const payload = Data.decode(Buffer.from(pbString, 'base64'));

    console.log(JSON.stringify(payload, null, 4));

    setPayload(payload);
  }, [protoString, pbString, rootMessage]);

  if (!payload) {
    return <></>;
  }

  /**/

  return (
    <div>
      <pre>{JSON.stringify(payload, null, 4)}</pre>
    </div>
  );
}

export default ProtoViewer;
