import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import QrReader from 'react-qr-reader';
import { ReactComponent as CircleLogo } from 'assets/logo-circle-companion.svg';
import { isLogin, logout } from 'utils/index';
import axios from 'axios';
import LoadingIndicator from 'components/loaders/indicator';
import ButtonIcon from 'components/misc/button-icon';
import Button from 'components/misc/button';
import '../../theme/components/message-fullscreen.scss';
import '../../theme/views/scan.scss';

const ReadQR = () => {
  const { t } = useTranslation('__global');
  const [scannerData, setScannerData] = useState({
    state: 'searching',
    data: '',
  });

  if (isLogin()) {
    let link = window.location.pathname + window.location.search;
    logout(true, link);
  }
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('t');

  const restartScanner = () => {
    setScannerData({
      state: 'searching',
      data: '',
    });
  };

  const handleScan = (data) => {
    if (data) {
      setScannerData({
        state: 'found',
        data: data,
      });
      addDeviceToCloud(data);
    }
  };
  const handleError = (err) => {
    console.error(err);
  };

  const addDeviceToCloud = (data) => {
    const device = data;
    setTimeout(() => {
      axios
        .post(process.env.REACT_APP_API_URL + 'devices/add', {
          id: device,
          token: token,
        })
        .then((response) => {
          console.log(response);
          var newData = Object.assign({}, scannerData);
          newData.state = response.data.status;
          setScannerData(newData);
        });
    }, 500);
  };

  return (
    <div className="scanView">
      <div className="header-area">
        <CircleLogo />
      </div>
      {scannerData.state === 'searching' ? (
        <div className="scanning">
          <QrReader
            delay={500}
            onError={handleError}
            onScan={handleScan}
            style={{ width: '100%' }}
          />
          <div className="info">
            <h3>{t('devices.qr.info_title')}</h3>
            <p>{t('devices.qr.info_text')}</p>
          </div>
        </div>
      ) : (
        ''
      )}
      {scannerData.state !== 'searching' ? (
        <div className="view">
          <div className="align-center v-stack fullscreen-message">
            {scannerData.state === 'found' ? (
              <LoadingIndicator />
            ) : scannerData.state === 'E_PERMISSION_MISSING' ? (
              <ButtonIcon icon="x" />
            ) : scannerData.state === 'SUCCESS' ? (
              <ButtonIcon icon="check-circle" />
            ) : (
              ''
            )}
            <p>{t('devices.qr.' + scannerData.state)}</p>
            {scannerData.state.startsWith('E_') === true ? (
              <Button
                action={restartScanner}
                label={t('devices.qr.rescan')}
                icon="rotate-ccw"
              />
            ) : (
              ''
            )}
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
export default ReadQR;
