// component to add a device manually
import axios from 'axios';
import { getAxiosConfig } from 'utils/index';
import Input from 'components/forms/input';
import SelectDeviceType from 'components/forms/select-device-type';
import Button from 'components/misc/button';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectPharmaUser from 'components/forms/select-pharma-user';

const DevicesAddManually = () => {
  const [newDeviceLoading, setNewDeviceLoading] = useState(false);
  const [dataset, updateDataset] = useState({
    data: {
      id: '',
      device: '',
      pharma: '',
    },
    errors: {
      id: false,
      device: false,
    },
  });
  const { t } = useTranslation('__ypsomed');

  const handleChange = (event) => {
    const newData = { ...dataset };
    newData.data[event.target.name] = event.target.value;
    updateDataset(newData);
  };

  const handleSubmit = (event) => {
    let hasError = false;
    setNewDeviceLoading(true);
    event.preventDefault();
    let datasetToUpdate = { ...dataset };

    if (datasetToUpdate.data.id === '') {
      hasError = true;
      datasetToUpdate.errors.id = "This field can't be empty";
    } else {
      datasetToUpdate.errors.id = false;
    }

    if (datasetToUpdate.data.device === '') {
      hasError = true;
      datasetToUpdate.errors.device = "This field can't be empty";
    } else {
      datasetToUpdate.errors.device = false;
    }

    if (datasetToUpdate.data.pharma === '') {
      hasError = true;
      datasetToUpdate.errors.pharma = "This field can't be empty";
    } else {
      datasetToUpdate.errors.pharma = false;
    }

    if (hasError) {
      updateDataset(datasetToUpdate);
      setNewDeviceLoading(false);
      return;
    }

    // axios post request to add the device
    axios
      .post(
        process.env.REACT_APP_API_URL + 'devices/create',
        {
          id: datasetToUpdate.data.id,
          type: datasetToUpdate.data.device,
          on_behalf: datasetToUpdate.data.pharma,
        },
        getAxiosConfig(),
      )
      .then((response) => {
        if (response.data.status === 'E_PERMISSION_MISSING') {
          datasetToUpdate.errors.id =
            'You do not have the permission to do this';
          updateDataset(datasetToUpdate);
        } else if (response.data.status === 'E_IMPOSSIBLE_DEVICE_ID') {
          datasetToUpdate.errors.id = 'This device already exists';
          updateDataset(datasetToUpdate);
        } else if (response.data.status === 'SUCCESS') {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setNewDeviceLoading(false);
      });
  };

  return (
    <div className="modal-content modal-add-device">
      <h3>{t('devices.add_modal.title')}</h3>
      <form className="login" onSubmit={handleSubmit}>
        <Input
          name="id"
          icon="key"
          label={t('devices.add_modal.id')}
          infield={false}
          value={dataset.data.id}
          error={dataset.errors.id}
          handleChange={handleChange}
        />
        <SelectDeviceType
          handleChange={handleChange}
          error={dataset.errors.device}
        />
        <SelectPharmaUser
          handleChange={handleChange}
          error={dataset.errors.pharma}
        />
        <Button
          label={t('devices.add_modal.create')}
          type="prominent"
          icon="arrow-right"
          isLoading={newDeviceLoading}
          splitted
          splitContent={<></>}
        />
      </form>
    </div>
  );
};
export default DevicesAddManually;
