import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getAxiosConfig, useProfile } from 'utils/index';
import PanelTop from 'components/layout/panel-top';
import ButtonBack from 'components/misc/button-back';
import PatientNavigation from 'components/navigations/detail/patient';
import LoadingIndicator from 'components/loaders/indicator';

const PatientsDetailTherapy = (props) => {
  const [userData, updateUserData] = useState({
    data: false,
    loading: true,
  });
  const { t } = useTranslation('__global');
  const userId = props.computedMatch.params.userId;
  const profile = useProfile();

  // get user information
  useEffect(() => {
    const getUserDataAsync = async () => {
      const result = await axios.get(
        process.env.REACT_APP_API_URL + 'users/get/' + userId,
        getAxiosConfig(),
      );
      if (result.data.status !== 'E_REQUEST_UNRELATED_USER') {
        const data = result.data;
        updateUserData({
          data: data,
          loading: false,
        });
      } else {
        toast.warn(result.data.status);
      }
    };

    getUserDataAsync();
  }, [userId]);

  return (
    <div className="view">
      <PanelTop
        loading={profile.loading}
        left={
          <>
            <ButtonBack to="/patients" />
            <h1>
              {!profile.loading ? (
                profile.data.role === 'role.hcp' ? (
                  <>
                    {userData.data.forename} {userData.data.lastname}{' '}
                  </>
                ) : (
                  <>
                    {t('base.manage')}{' '}
                    <span className="fade">{userData.data.email}</span>
                  </>
                )
              ) : (
                '..'
              )}
            </h1>
          </>
        }
        navigation={
          <PatientNavigation item={userData.data.id} active="therapy" />
        }
      />
      {userData.loading ? (
        <div className="content">
          <LoadingIndicator />
        </div>
      ) : (
        <div className="content">
          <p>therapy...</p>
        </div>
      )}
    </div>
  );
};

export default PatientsDetailTherapy;
