import axios from 'axios';
import { getAxiosConfig } from 'utils/index';

export const injectionArchiveByDrugIdentifier = async (drugIdentifier) => {
  await axios.post(
    process.env.REACT_APP_API_URL + 'injection/archive',
    {
      drug: drugIdentifier,
    },
    getAxiosConfig(),
  );
};
