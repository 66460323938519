import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getAxiosConfig, useProfile } from 'utils/index';
import PanelTop from 'components/layout/panel-top';
import ButtonBack from 'components/misc/button-back';
import LoadingIndicator from 'components/loaders/indicator';
import Icons from 'components/misc/icons';
import ButtonLink from 'components/misc/button-link';
import ProfileForm from 'components/organisms/form-profile';

const UsersEdit = (props) => {
  const [userData, updateUserData] = useState({
    data: false,
    loading: true,
  });
  const [errors, updateErrors] = useState([]);
  const { t } = useTranslation('__global');
  const userId = props.computedMatch.params.userId;
  const [actionLoading, updateActionLoading] = useState(false);
  const profile = useProfile();

  const startActivate = () => {
    if (actionLoading === 'activate') return;
    updateActionLoading('activate');
    axios
      .get(
        process.env.REACT_APP_API_URL + 'users/activate/' + userId,
        getAxiosConfig(),
      )
      .then((response) => {
        if (response.data.status === 'SUCCESS') {
          const newData = userData;
          newData.data.active = 1;
          updateUserData(newData);
          updateActionLoading('');
          toast.success(t('users.activated'));
        }
      });
  };

  const startDeactivate = () => {
    if (actionLoading === 'deactivate') return;
    updateActionLoading('deactivate');
    axios
      .get(
        process.env.REACT_APP_API_URL + 'users/deactivate/' + userId,
        getAxiosConfig(),
      )
      .then((response) => {
        if (response.data.status === 'SUCCESS') {
          const newData = userData;
          newData.data.active = 0;
          updateUserData(newData);
          updateActionLoading('');
          toast.success(t('users.deactivated'));
        }
        if (response.data.status === 'E_DEACTIVATE_SELF_DENIED') {
          updateActionLoading('');
          toast.warn(t('error.E_DEACTIVATE_SELF_DENIED'));
        }
      });
  };

  const startPasswordReset = () => {
    if (actionLoading === 'reset') return;
    updateActionLoading('reset');
    axios
      .get(
        process.env.REACT_APP_API_URL +
          'users/reset-password?user=' +
          encodeURI(userData.data.email),
        getAxiosConfig(),
      )
      .then((response) => {
        if (response.data.status === 'SUCCESS') {
          updateActionLoading('');
          toast.success(t('users.pw_reset_sent'));
        }
      });
  };

  const handleSubmit = (fields) => (event) => {
    event.preventDefault();
    var data = {};
    for (var field in fields) {
      if (fields[field].value) {
        data[fields[field].key] = fields[field].value;
      } else {
        data[fields[field].key] = null;
      }
    }
    axios
      .post(
        process.env.REACT_APP_API_URL + 'users/update/' + userId,
        data,
        getAxiosConfig(),
      )
      .then((response) => {
        if (response.data.status === 'FORM_ERROR') {
          updateErrors(response.data.fields);
        }
        if (response.data.warning === 'W_USER_EMAIL_EXISTS') {
          toast.warn(t('warning.W_USER_EMAIL_EXISTS'));
        }
      });

    toast.success(t('base.saved'));
  };

  // get user information
  useEffect(() => {
    const getUserDataAsync = async () => {
      const result = await axios.get(
        process.env.REACT_APP_API_URL + 'users/get/' + userId,
        getAxiosConfig(),
      );
      if (result.data.status !== 'E_REQUEST_UNRELATED_USER') {
        const data = result.data;
        updateUserData({
          data: data,
          loading: false,
        });
      } else {
        toast.warn(result.data.status);
      }
    };

    getUserDataAsync();
  }, [userId]);

  return (
    <div className="view">
      <PanelTop
        loading={profile.loading}
        left={
          <>
            <ButtonBack />
            <h1>
              {!profile.loading ? (
                profile.data.role === 'role.hcp' ? (
                  <>
                    <span className="fade">{t('patients.dossier')}</span>{' '}
                    {userData.data.forename} {userData.data.lastname}{' '}
                  </>
                ) : (
                  <>
                    {t('base.manage')}{' '}
                    <span className="fade">{userData.data.email}</span>
                  </>
                )
              ) : (
                '..'
              )}
            </h1>
          </>
        }
      />
      {userData.loading ? (
        <div className="content">
          <LoadingIndicator />
        </div>
      ) : (
        <div className="content">
          <h4 className="spaced">
            <Icons show="privacy-shield" /> {t('users.manage_access')}
          </h4>
          <div className="flex flex-space">
            <ButtonLink
              icon="privacy-shield"
              label={t('users.password_reset')}
              loading={actionLoading === 'reset' ? true : false}
              action={startPasswordReset}
            />
            {userData.data.active === 1 ? (
              <ButtonLink
                icon="lock"
                label={t('users.deactivate')}
                loading={actionLoading === 'deactivate' ? true : false}
                action={startDeactivate}
              />
            ) : (
              <ButtonLink
                icon="lock"
                label={t('users.activate')}
                loading={actionLoading === 'activate' ? true : false}
                action={startActivate}
              />
            )}
          </div>
          <hr />
          <h4>
            <Icons show="person" /> {t('users.profile_information')}
          </h4>
          <ProfileForm
            email={userData.data.email || ''}
            phone={userData.data.phone || ''}
            forename={userData.data.forename || ''}
            lastname={userData.data.lastname || ''}
            street={userData.data.street || ''}
            street_no={userData.data.street_no || ''}
            zip={userData.data.zip || ''}
            place={userData.data.place || ''}
            handleSubmit={handleSubmit}
            errors={errors}
            formLabel={t('users.save')}
          />
        </div>
      )}
    </div>
  );
};

export default UsersEdit;
