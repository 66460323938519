import ButtonIcon from 'components/misc/button-icon';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

const TableZapper = ({
  total = 0,
  page = 0,
  pagesize = 0,
  directAction = (target) => {
    console.log(target);
  },
  nextAction = () => {
    console.log('zap-to-next-undefined');
  },
  prevAction = () => {
    console.log('zap-to-prev-undefined');
  },
}) => {
  const [directZappers, setDirectZappers] = useState([]);
  const { t } = useTranslation('__global');
  const nullPage = page - 1;
  const from = nullPage * pagesize + 1;
  let to = nullPage * pagesize + pagesize;
  if (to > total) {
    to = total;
  }
  const pages = Math.ceil(total / pagesize);

  useEffect(() => {
    var dZaps = [];
    for (var index = 1; index <= pages; index++) {
      var clazzes = '';
      if (index == page) {
        clazzes += 'active';
      }
      // only show -3 to +3 pages from current page as direct zappers
      if (index < parseInt(page, 10) - 3 || index > parseInt(page, 10) + 3) {
        continue;
      }
      dZaps.push(
        <a
          className={clazzes}
          onClick={(event) => {
            directAction(event.target.dataset.index);
          }}
          key={index}
          data-index={index}>
          {index}
        </a>,
      );
    }
    setDirectZappers(dZaps);
  }, [page, pages]);

  return (
    <div className="table-zapper">
      <small>
        <Trans t={t} i18nKey="table.zapper" total={total} from={from} to={to}>
          start {{ from }} to {{ to }} total {{ total }}
        </Trans>
      </small>
      <div className="zapper">
        {nullPage > 0 ? (
          <ButtonIcon
            icon="arrow-left"
            variation="default"
            action={prevAction}
          />
        ) : null}
        {directZappers}
        {nullPage < pages - 1 ? (
          <ButtonIcon icon="arrow-right" action={nextAction} />
        ) : null}
      </div>
    </div>
  );
};

export default TableZapper;
