import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icons from 'components/misc/icons';

const NavigationPharma = () => {
  let location = useLocation();
  const { t } = useTranslation('__global');

  return (
    <nav>
      <Link
        to="/"
        id="sidebar-dashboard"
        className={`${location.pathname === '/' ? 'active' : ''}`}>
        <Icons show="home" />{' '}
        <span className="text">{t('base.dashboard')}</span>
      </Link>
      <Link
        to="/devices"
        className={`${location.pathname === '/devices' ? 'active' : ''}`}>
        <Icons show="server" />
        <span className="text">{t('devices.title')}</span>
      </Link>
      {/*<Link
        to="/drugrecall"
        className={`${location.pathname === '/drugrecall' ? 'active' : ''}`}>
        <Icons show="injector" />
        <span className="text">{t('base.drug_recall')}</span>
      </Link>*/}
      <hr />
    </nav>
  );
};
export default NavigationPharma;
