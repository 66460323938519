import Error from 'components/misc/error';
import Icons from 'components/misc/icons';
import React from 'react';

const Input = ({
  label = 'Undefined <label>',
  name = 'no-name-set',
  type = 'text',
  icon = false,
  handleChange = () => {},
  handleKeyDown = () => {},
  error = false,
  infield = true,
  disable = false,
  value = '',
}) => {
  const setFocus = (e) => {
    let container = e.target.closest('.form-element');
    container.classList.add('focus');
  };

  const setBlur = (e) => {
    let container = e.target.closest('.form-element');
    if (e.target.value !== '') return;
    container.classList.remove('focus');
  };

  let hasError = error ? ' has-error' : '';
  let isInfield = infield ? ' infield' : '';
  let focus = error || value !== '' ? ' focus' : '';
  // if infield is false, set string to "label-above"
  let above = !infield ? ' label-above' : '';
  let clazzes = `form-element${hasError}${isInfield}${focus}${above}`;
  let dizzable = disable ? 'disabled' : '';

  return (
    <div className={clazzes}>
      <label>{label}</label>
      <div className="field">
        <input
          name={name}
          disabled={dizzable}
          value={value}
          type={type}
          onChange={(e) => {
            setFocus(e);
            handleChange(e);
          }}
          onKeyDown={handleKeyDown}
          onFocus={setFocus}
          onBlur={setBlur}
        />
        {icon ? <Icons show={icon} /> : ''}
        {hasError ? <Error message={error} /> : ''}
      </div>
    </div>
  );
};
export default Input;
