import useUsers from 'hooks/users';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from './select';

const SelectPharmaUser = ({ handleChange = () => {}, error = '' }) => {
  const { t } = useTranslation('__global');
  const pharmaUsers = useUsers('role.pharma');
  const [pharmaUserSelection, selectPharmaUserSelection] = useState([]);

  useEffect(() => {
    if (pharmaUsers.length > 0) {
      // loop through pharma users and create array
      // with objects of name and id as value
      const pharmaUsersArray = [
        {
          label: t('pharmaSelection.choose'),
          value: '',
        },
      ];
      pharmaUsers.forEach((user) => {
        pharmaUsersArray.push({
          label: user.forename + ' ' + user.lastname + ' (' + user.email + ')',
          value: user.id,
        });
      });
      selectPharmaUserSelection(pharmaUsersArray);
    }
  }, [pharmaUsers]);

  return (
    <Select
      label={t('pharmaSelection.label')}
      name="pharma"
      values={pharmaUserSelection}
      handleChange={handleChange}
      error={error}
    />
  );
};
export default SelectPharmaUser;
