import axios from 'axios';
import { useEffect, useState } from 'react';
import { createBrowserHistory } from 'history';
import '../components/misc/i18n';
import moment from 'moment';

export const CONF_TOKEN = 'caladrius_jwt';
export const LAST_AUTH_CHECK = 'auth-check';
export const getAxiosConfig = () => {
  return {
    headers: {
      Authorization: `Bearer ` + localStorage.getItem(CONF_TOKEN),
      'Access-Control-Allow-Origin': '*',
      'accept-language': localStorage.getItem('i18nextLng'),
    },
  };
};

export const formatDate = (date) => {
  // check if date is of type date
  if (date instanceof Date) {
    return moment(date).format('DD.MM.YYYY HH:mm:ss');
  }
  if (date.charAt(date.length - 1) != 'Z') {
    date = date + 'Z';
  }

  var wellformed = new Date(date);
  return wellformed.toLocaleDateString('de-CH', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
};

export const login = (token) => {
  localStorage.setItem(CONF_TOKEN, token);
};

export const logout = (force, redirect) => {
  if (!force) {
    force = false;
  }
  // if not already logged out.
  if (localStorage.getItem(CONF_TOKEN)) {
    console.warn('remove login token ' + CONF_TOKEN);
    localStorage.removeItem(CONF_TOKEN);
    let history = createBrowserHistory({ forceRefresh: force });
    if (redirect) {
      history.push(redirect);
      return;
    }
    history.push('/login');
  }
};

export const isLogin = (force) => {
  if (!force) {
    force = false;
  }
  let lastCheck = localStorage.getItem(LAST_AUTH_CHECK);
  let doCheck = false;
  if (!lastCheck) {
    doCheck = true;
    localStorage.setItem(LAST_AUTH_CHECK, new Date());
  }
  if (lastCheck) {
    var timeDiff = Math.abs(new Date() - new Date(lastCheck)) / 1000;
    // more than 10 seconds ago
    if (timeDiff > 3) {
      localStorage.setItem(LAST_AUTH_CHECK, new Date());
      doCheck = true;
    }
  }
  if (doCheck || force) {
    axios
      .get(process.env.REACT_APP_API_URL + 'auth', getAxiosConfig())
      .then((res) => {
        if (res.data.Authorization !== true) {
          logout(true);
        }
      })
      .catch(() => {
        logout(true);
      });
  }

  if (localStorage.getItem(CONF_TOKEN)) {
    return true;
  }
  return false;
};

export const useOutsideAlerter = (ref, callback) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
};

export const useProfile = (toBeCalled) => {
  const [profile, setProfile] = useState({
    data: null,
    loading: true,
  });

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(
        process.env.REACT_APP_API_URL + 'users/self',
        getAxiosConfig(),
      );
      setProfile({ data: result.data, loading: false });
      if (toBeCalled) {
        toBeCalled(result.data);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  return profile;
};

export const padNum = (str, padString, length) => {
  str = str.toString();
  while (str.length < length) {
    str = padString + str;
  }
  return str;
};

export const helloMessages = [
  'Grüezi 👋',
  'Guten Tag 👋',
  'nǐ hăo 👋',
  'Hej 👋',
  'Konnichi wa 👋',
  'Salâm 👋',
  'Olá 👋',
  'Hoi 👋',
  'Bonjour 👋',
  'Ciao 👋',
  'Hello 👋',
];

export const getRandomInt = (max) => {
  return Math.floor(Math.random() * Math.floor(max));
};

export const isApiAvailable = async () => {
  let apiAvailable = true;
  await axios
    .get(process.env.REACT_APP_API_URL + 'auth', getAxiosConfig())
    .catch(() => {
      apiAvailable = false;
    });
  return apiAvailable;
};

export const getTimeAgoMessage = (t, timestamp) => {
  let currentTime = moment();
  let timeParameter = moment(timestamp * 1000);

  let diffInMinutes = currentTime.diff(timeParameter, 'minutes');

  if (diffInMinutes <= 1) return t('environments.detail.time.seconds');
  else if (diffInMinutes < 60)
    return t('environments.detail.time.minutes', { minutes: diffInMinutes });
  else if (diffInMinutes < 1440)
    return t('environments.detail.time.hours', {
      hours: Math.round(diffInMinutes / 60),
    });
  else
    return t('environments.detail.time.days', {
      days: Math.round(diffInMinutes / 60 / 24),
    });
};

export const getEnvironmentStatus = (services) => {
  const serviceStatuses = services.map((service) =>
    getServiceStatus(service.responses),
  );

  if (serviceStatuses.every((s) => s === 'OFFLINE')) return 'OFFLINE';
  if (serviceStatuses.some((s) => s === 'OFFLINE' || s === 'UNKNOWN'))
    return 'PARTIAL';
  return 'ONLINE';
};

export const getColorFromStatus = (status) => {
  switch (status) {
    case 'ONLINE':
      return 'green';
    case 'PARTIAL':
    case 'UNKNOWN':
      return 'yellow';
    case 'OFFLINE':
      return 'red';
  }
};

export const getServiceStatus = (responses) => {
  return responses.length > 0
    ? responses[0].is_success
      ? 'ONLINE'
      : 'OFFLINE'
    : 'UNKNOWN';
};

/**
 * @description Check if the logging API is available
 * @author LETI2
 * @returns {Promise<boolean>}
 */
export const isLoggingApiAvailable = async () => {
  let isApiAvailable = true;
  try {
    await axios.get(`${process.env.REACT_APP_LOG_API_URL}/ping`, {
      headers: {
        authorization: `Bearer ${localStorage.getItem('caladrius_jwt')}`,
      },
    });
  } catch (e) {
    isApiAvailable = false;
  }
  return isApiAvailable;
};

/**
 * @description get a formatted date from a timestamp
 * @author LETI2
 * @param {number} captured - timestamp
 * @param {boolean} includeMS - if milliseconds should be included in the date
 * @returns {string}
 */
export const getLogDate = (captured, includeMS = false) => {
  // 1000 because date takes milliseconds
  const date = moment(captured * 1000);
  return date.format(`DD.MM.YYYY - HH:mm:ss${includeMS ? ':SSS' : ''}`);
};

/**
 * @description get all services
 * @returns {Promise<Array>}
 * @author LETI2
 */
export const getServices = async () => {
  try {
    const result = await axios.get(
      `${process.env.REACT_APP_API_URL}/users/services`,
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem('caladrius_jwt')}`,
        },
      },
    );
    return await result.data;
  } catch (e) {
    console.error(e);
  }
};

/**
 * @description check if object is empty
 * @author LETI2
 * @param {Object} obj - object to check
 * @returns {boolean} - true if the given object is empty
 */
export const isEmpty = (obj) => {
  // Return false if object has any properties
  for (let x in obj) {
    return false;
  }
  return true;
};

export const pbFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);

      // Encode the binary data as base64
      const base64 = Buffer.from(data).toString('base64');

      resolve(base64);
    };

    reader.onerror = (event) => {
      reject(event.target.error);
    };

    reader.readAsArrayBuffer(file);
  });
};

export const isBase64 = (str) => {
  try {
    return window.btoa(window.atob(str)) === str;
  } catch (err) {
    return false;
  }
};

/**
 * @description Get query string for the urlConverts query object to a string
 * @example {a: 1, b: 2} => ?a=1&b=2, {a: [1, 2], b: 2} => ?a=1,2&b=2
 * @author LETI2§
 */
export const getParameterQuery = (query) => {
  const params = new URLSearchParams(query).toString().replaceAll('%2C', ',');

  // Remove empty params
  const paramsArray = params.split('&');
  const filteredParams = paramsArray.filter(
    (param) => param.split('=')[1] !== '',
  );
  const filteredParamsString = filteredParams.join('&');

  return filteredParamsString.length > 0 ? `?${filteredParamsString}` : '';
};

export function niceBytes(x) {
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let l = 0,
    n = parseInt(x, 10) || 0;
  while (n >= 1024 && ++l) {
    n = n / 1024;
  }

  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
}
