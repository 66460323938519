import Splitline from 'components/misc/split';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const ToolsNavigation = ({ active = 'overview' }) => {
  const { t } = useTranslation('__ypsomed');

  const base = '/tools';
  const items = [
    {
      id: 'overview',
      name: t('tools.nav.intro'),
      link: base + '/',
    },
    {
      id: 'protobuf-reader',
      name: t('tools.nav.protobuf_reader'),
      link: base + '/protobuf-reader',
    },
    {
      id: 'protobuf-writer',
      name: t('tools.nav.protobuf_writer'),
      link: base + '/protobuf-writer',
    },
  ];

  return (
    <div className="subnavigation">
      {items.map((item, index) => (
        <Link
          className={active === item.id ? 'active' : ''}
          to={item.link}
          key={index}>
          {item.name}
        </Link>
      ))}
      <Splitline height={'thick'} />
    </div>
  );
};
export default ToolsNavigation;
