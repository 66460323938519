import React from 'react';
import ReactTooltip from 'react-tooltip';

const TableRow = ({ type = 'td', data = [] }) => {
  let clazzes = '';
  if (type == 'th') {
    clazzes += ' head';
  }

  return (
    <tr className={clazzes}>
      {data.map((cell, key) =>
        typeof cell === 'string' || cell == null ? (
          type == 'th' ? (
            <th key={key}>{cell}</th>
          ) : (
            <td key={key}>{cell}</td>
          )
        ) : type == 'th' ? (
          <th key={key}>{cell.value}</th>
        ) : typeof cell == 'object' && !('tooltip' in cell) ? (
          <td key={key}>{cell}</td>
        ) : (
          <td
            key={key}
            data-multiline="true"
            data-tip={cell.tooltip ? cell.tooltip : ''}>
            <ReactTooltip className="tooltip" effect="solid" />
            {cell.value}
          </td>
        ),
      )}
    </tr>
  );
};

export default TableRow;
