import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { createBrowserHistory } from 'history';
import { ReactComponent as Logo } from 'assets/logo-companion.svg';
import { getRandomInt, helloMessages, login } from 'utils/index';

import 'theme/views/login.scss';
import Button from 'components/misc/button';
import Splitline from 'components/misc/split';
import Input from 'components/forms/input';

const Login = () => {
  const { t } = useTranslation('__global');
  const [loginLoading, setLoginLoading] = useState(false);
  const [hello, setHello] = useState('');
  const [dataset, updateDataset] = useState({
    data: {
      email: '',
      password: '',
    },
    errors: {
      email: false,
      password: false,
    },
  });

  useEffect(() => {
    setHello(helloMessages[getRandomInt(helloMessages.length)]);
  }, []);

  const handleChange = (event) => {
    const fieldToUpdate = event.target.name;
    const newData = dataset.data;
    const oldErrors = dataset.errors;
    newData[fieldToUpdate] = event.target.value;
    updateDataset({
      data: newData,
      errors: oldErrors,
    });
  };

  const handleSubmit = (event) => {
    setLoginLoading(true);
    event.preventDefault();
    axios
      .post(process.env.REACT_APP_API_URL + 'auth/login', {
        email: dataset.data.email,
        password: dataset.data.password,
      })
      .then((response) => {
        if (response.data.api_token) {
          login(response.data.api_token);
          let history = createBrowserHistory({ forceRefresh: true });
          history.push('/');
        } else {
          let oldData = dataset.data;
          updateDataset({
            data: oldData,
            errors: {
              email: t('error.E_INVALID_LOGIN'),
              password: false,
            },
          });
        }
        setLoginLoading(false);
      })
      .catch((error) => {
        let newState = {};
        let oldData = dataset.data;
        for (var prop in error.response.data) {
          newState[prop] = error.response.data[prop][0];
        }
        updateDataset({
          data: oldData,
          errors: newState,
        });
      });
  };

  return (
    <div className="login-frame w100 bg-grey full-height">
      <div className="container">
        <div className="top">
          <Logo />
        </div>
        <div className="bottom">
          <div className="intro">
            <h2>{hello}</h2>
            <p>{t('login.intro_text')}</p>
            {/*<Button
              icon="arrow-right"
              label={t('login.registration_button')}
              stretch={true}
              action={() => {
                console.log('yes');
              }}
            />*/}
          </div>
          <Splitline type="vertical" spacing="huge" />
          <div className="form">
            <form className="login" onSubmit={handleSubmit}>
              <h3>{t('login.title')}</h3>
              <Input
                name="email"
                icon="mail"
                label={t('base.email')}
                value={dataset.data.email}
                handleChange={handleChange}
                error={dataset.errors.email}
              />
              <Input
                name="password"
                icon="lock"
                label={t('base.password')}
                value={dataset.data.password}
                type="password"
                handleChange={handleChange}
                error={dataset.errors.password}
              />
              <Button
                label={t('base.signin')}
                type="prominent"
                icon="arrow-right"
                isLoading={loginLoading}
                splitted
                splitContent={<></>}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
