import PrivateRoute from 'components/routes/private';
import React from 'react';
import { Switch } from 'react-router-dom';
import DeviceDetail from 'views/ypsomed/device';
import Devices from 'views/ypsomed/devices';
import RoutesGeneral from './general';
import Logs from 'views/ypsomed/logs';
import Tools from 'views/ypsomed/tools';
import ToolsProtobufReader from 'views/ypsomed/tools/protobufReader';
import ToolsProtobufWriter from 'views/ypsomed/tools/protobufWriter';
import Users from '../../../views/root/users';
import UsersCreate from '../../../views/root/users-create';
import UsersEdit from '../../../views/root/users-edit';
import Drugrecall from '../../../views/ypsomed/drug-recall';
import Services from '../../../views/ypsomed/services';
import Environments from '../../../views/ypsomed/environments';
import EnvironmentsDetail from '../../../views/ypsomed/environments-detail';
import ServiceDetail from '../../../views/ypsomed/service-detail';

const RoutesYpsomed = () => {
  return (
    <Switch>
      <PrivateRoute component={Users} path="/users" exact />
      <PrivateRoute component={UsersCreate} path="/users/new" exact />
      <PrivateRoute component={UsersEdit} path="/users/edit/:userId" exact />
      <PrivateRoute component={Devices} path="/devices" exact />
      <PrivateRoute component={DeviceDetail} path="/device/:id" exact />
      <PrivateRoute component={Drugrecall} path="/drugrecall" exact />
      <PrivateRoute component={Tools} path="/tools" exact />
      <PrivateRoute component={Services} path="/services" exact />
      <PrivateRoute component={Environments} path="/environments" exact />
      <PrivateRoute component={EnvironmentsDetail} path="/environments/:id" />
      <PrivateRoute component={ServiceDetail} path="/services/:id" />
      <PrivateRoute
        component={ToolsProtobufReader}
        path="/tools/protobuf-reader"
        exact
      />
      <PrivateRoute
        component={ToolsProtobufWriter}
        path="/tools/protobuf-writer"
        exact
      />
      {process.env.REACT_APP_LOGS_ENABLED && (
        <PrivateRoute component={Logs} path="/logs" exact />
      )}
      <RoutesGeneral />
    </Switch>
  );
};
export default RoutesYpsomed;
