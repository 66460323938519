import Input from 'components/forms/input';
import Button from 'components/misc/button';
import ButtonBack from 'components/misc/button-back';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ProfileForm = ({
  email = '',
  phone = '',
  forename = '',
  lastname = '',
  street = '',
  street_no = '',
  zip = '',
  place = '',
  additionalFields = '',
  disabled = [],
  formLabel = false,
  handleSubmit = () => {
    console.log('no handleSubmit');
  },
  errors = {},
  hideBack = false,
}) => {
  const { t } = useTranslation('__global');
  const [fields, updateFields] = useState([
    {
      key: 'email',
      label: t('base.email'),
      value: email,
      disable: disabled.includes('email') ? true : false,
    },
    {
      key: 'phone',
      label: t('base.phone'),
      value: phone,
      disable: disabled.includes('phone') ? true : false,
    },
    {
      key: 'forename',
      label: t('profile.forename'),
      value: forename,
      disable: disabled.includes('forename') ? true : false,
    },
    {
      key: 'lastname',
      label: t('profile.lastname'),
      value: lastname,
      disable: disabled.includes('lastname') ? true : false,
    },
    {
      key: 'street',
      label: t('profile.street'),
      value: street,
      disable: disabled.includes('street') ? true : false,
    },
    {
      key: 'street_no',
      label: t('profile.street_no'),
      value: street_no,
      disable: disabled.includes('street_no') ? true : false,
    },
    {
      key: 'zip',
      label: t('profile.zip'),
      value: zip,
      disable: disabled.includes('zip') ? true : false,
    },
    {
      key: 'place',
      label: t('profile.place'),
      value: place,
      disable: disabled.includes('place') ? true : false,
    },
  ]);

  let classEven = 'col-s-start col-e-mid';
  let classOdd = 'col-s-mid col-e-end';

  const updateData = (index) => (e) => {
    let newData = fields.slice();
    newData[index].value = e.target.value;
    updateFields(newData);
  };

  if (!formLabel) {
    formLabel = t('base.create');
  }

  return (
    <form
      className="profile-form col-s-start col-e-end grid"
      onSubmit={handleSubmit(fields)}>
      {fields.map((field, index) => (
        <div key={field.key} className={index % 2 === 0 ? classEven : classOdd}>
          <Input
            label={field.label}
            disable={field.disable}
            error={errors[field.key] ? t('error.' + errors[field.key]) : ''}
            value={field.value}
            name={field.key}
            handleChange={updateData(index)}
          />
        </div>
      ))}
      <div className="col-s-start col-e-end">{additionalFields}</div>
      <div className="form-element splitted w100">
        {!hideBack ? <ButtonBack label={t('base.cancel')} /> : ''}
        <Button label={formLabel} type="prominent" icon="arrow-right" />
      </div>
    </form>
  );
};

export default ProfileForm;
