import Icons from 'components/misc/icons';
import Tag from 'components/misc/tag';
import React from 'react';
import { formatDate } from 'utils/index';
import { useTranslation } from 'react-i18next';
import { useMoments } from 'utils/dates';

import '../../theme/components/card-injection.scss';

const CardInjectionPharma = ({ data }) => {
  const { t } = useTranslation('__global');
  var deviceName = data.device.name;
  var date = new Date(Date.parse(data.date));
  const dater = useMoments();

  var timeStr = dater(date.toString() + 'Z').fromNow();
  var dateStr = formatDate(data.date);

  var isNew = true;

  const diff_minutes = (dt2, dt1) => {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  };

  var diff = diff_minutes(new Date(date), new Date());
  if (diff > 3) {
    isNew = false;
  }

  return (
    <div className="card injection-card pharma">
      <div className="col-s-1 col-e-2">
        <Icons show={'injector'} />
      </div>
      <div className="col-s-2 col-e-6 flex">
        <div className="v-stack device-data">
          <div className="text-regular c-gray-300">{timeStr}</div>
          <h4 className="mt-0">{dateStr}</h4>
        </div>
      </div>
      <div className="col-s-6 col-e-9 flex">
        <div className="v-stack device-data">
          <div className="text-regular c-gray-300">
            {t('devices.title_single')}
          </div>
          <h4 className="mt-0">{deviceName}</h4>
        </div>
      </div>
      <div className="flex-end align-center col-s-10 col-e-13 flex-space">
        {isNew ? <Tag value="New" /> : ''}
      </div>
    </div>
  );
};

export default CardInjectionPharma;
