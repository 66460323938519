import React, { useEffect } from 'react';
import '../../theme/components/services.scss';
import ServiceHistoryChart from '../charts/service-history-chart';
import axios from 'axios';
import ColorHash from 'color-hash';
import moment from 'moment';
import { getAxiosConfig } from '../../utils';

const colorHash = new ColorHash();
const ServiceHistory = () => {
  const [services, setServices] = React.useState([]);

  useEffect(() => {
    const getServices = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_MONITORING_API_URL}/services/standalone`,
          getAxiosConfig(),
        );
        setServices(response.data);
      } catch (e) {
        console.error(e);
      }
    };

    getServices();
  }, []);

  return (
    <div className="services-card card">
      <div className="card-header">
        <h3>Service History</h3>
        <div className="line-diagram-legend">
          {services.map((service) => (
            <p
              key={service._id}
              style={{ color: colorHash.hex(service.abbreviation) }}>
              {service.abbreviation}
            </p>
          ))}
        </div>
      </div>
      <div className="splitline" />
      <ServiceHistoryChart
        services={services}
        from={moment().subtract({ hours: 24 }).unix()}
        to={moment().unix()}
      />
    </div>
  );
};
export default ServiceHistory;
