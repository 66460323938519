import PanelTop from 'components/layout/panel-top';
// import Services from '../../components/cards/services';
//import ServiceHistory from 'components/cards/service-history';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import '../../theme/views/dashboard-ypsomed.scss';
import ServiceHistory from '../../components/cards/service-history';
import Services from '../../components/cards/services';

const DashboardYpsomed = ({ profile }) => {
  const { t } = useTranslation('__global');
  return (
    <div className="view dashboard">
      <PanelTop
        left={
          <>
            <small className="uppercase special">
              {t('dashboards.default.subtitle')}
            </small>
            <h1>
              <Trans
                t={t}
                i18nKey="dashboards.default.title"
                values={{
                  user: `${profile.data.forename} ${
                    profile.data.lastname
                      ? profile.data.forename
                        ? profile.data.lastname
                        : ''
                      : ''
                  }`,
                }}
              />
            </h1>
          </>
        }
      />
      <div className="cards">
        <Services />
        <ServiceHistory />
      </div>
    </div>
  );
};
export default DashboardYpsomed;
