import PanelDemo from 'components/layout/panel-demo';
import Sidebar from 'components/layout/sidebar';
import MainSwitch from 'components/misc/switch';
import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { isApiAvailable, isLogin } from 'utils/index';
import 'react-toastify/dist/ReactToastify.css';
import BetaFlag from 'components/layout/betaflag';
import Error from 'components/misc/error';

const App = () => {
  const [login, setLoginState] = useState(false);
  const clickRef = useRef(null);
  const loginRef = useRef(null);
  const [apiAvailable, setApiAvailable] = useState(true);

  useEffect(() => {
    const check = async () => {
      if (!(await isApiAvailable())) {
        setApiAvailable(false);
      }
    };
    check();
  }, []);

  useEffect(() => {
    if (isLogin()) {
      setLoginState(true);
    } else {
      setLoginState(false);
    }
  }, [loginRef]);

  if (!apiAvailable) {
    return <Error message="API doesn't seem to be available" />;
  }

  return (
    <div className="main-frame" ref={clickRef}>
      <BrowserRouter ref={loginRef}>
        <div className="content">
          <PanelDemo />
          {login ? <Sidebar /> : ''}
          <MainSwitch />
          {window.location.hostname.indexOf('beta') >= 0 ? <BetaFlag /> : <></>}
        </div>
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default App;
