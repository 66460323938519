import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonLink from './button-link';
import Icons from './icons';

import '../../theme/components/message-inline.scss';

const MessageInline = ({
  message = '',
  type = 'default',
  title = '',
  icon = '',
  action_label = '',
  action = false,
  actionRead = null,
  actionRead_label = '',
}) => {
  const { t } = useTranslation('__global');
  let clazzes = 'inline-message flex splitted ' + type;

  if (!action_label) {
    action_label = t('base.more_information');
  }

  return (
    <div className={clazzes}>
      <div className="left flex">
        {icon ? <Icons show={icon} /> : ''}
        <div className="text base-padding-r">
          {title ? <p className="title">{title}</p> : ''}
          {message}
        </div>
      </div>
      <div className="vertical-button-stack">
        {action ? (
          <ButtonLink
            label={action_label}
            action={action}
            icon="external-link"
          />
        ) : (
          ''
        )}
        {actionRead != null ? (
          <ButtonLink
            label={actionRead_label}
            action={actionRead}
            icon="check"
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default MessageInline;
