import axios from 'axios';
import Input from 'components/forms/input';
import Button from 'components/misc/button';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getAxiosConfig } from 'utils/index';
import BoxMessage from './box-message';

const BoxAddDrugRecall = ({ onAdded = () => {} }) => {
  const { t } = useTranslation('__global');
  const [addMediUDIregexLoading, setMediUDIregexLoading] = useState(false);
  const [mediUDIregex, setMediUDIregex] = useState('');
  const [description, setDescription] = useState('');
  const [link, setLink] = useState('');
  const [on_behalf, setOnBehalf] = useState('');

  const updateMedi = (event) => {
    var id = event.target.value;
    id = id.replaceAll(' ', '');
    setMediUDIregex(id);
  };

  const updateDesc = (event) => {
    var desc = event.target.value;
    setDescription(desc);
  };
  const updateLink = (event) => {
    var tmp = event.target.value;
    setLink(tmp);
  };

  const updateOnBehalf = (event) => {
    var tmp = event.target.value;
    setOnBehalf(tmp);
  };

  const handleAddmediUDIregex = (event) => {
    event.preventDefault();
    if (!mediUDIregex) {
      return;
    }
    axios
      .post(
        process.env.REACT_APP_API_URL + 'drug_recall/create',
        {
          mediUDIregex: [mediUDIregex],
          description: description,
          link: link,
          on_behalf: on_behalf === '' ? null : on_behalf,
        },
        getAxiosConfig(),
      )
      .then((response) => {
        var addResult = response.data.report[0].status;
        if (addResult == 'already_exists') {
          toast.warning(t('drugrecall.addbox.already_exists'));
        } else {
          toast.success(t('drugrecall.addbox.saved'));
        }
        setMediUDIregex('');
        setDescription('');
        setLink('');
        setOnBehalf('');
        setMediUDIregexLoading(false);
        onAdded();
      })
      .catch((error) => {
        // read response message
        var message = error.response.data.status;
        toast.warning(message);
      });
  };

  const addMediUDIregexForm = (
    <div>
      <form onSubmit={handleAddmediUDIregex}>
        <Input
          name="mediUDIregex"
          icon="devices"
          label={t('drugrecall.mediUDI')}
          value={mediUDIregex}
          handleChange={updateMedi}
          //handleChange={updateDeviceId}
          //error={deviceIdError ? t('error.' + "d") : ''}
        />
        <Input
          name="drugrecall.description"
          icon="devices"
          label={t('drugrecall.description')}
          value={description}
          handleChange={updateDesc}
          //error={deviceIdError ? t('error.' + deviceIdError) : ''}
        />
        <Input
          name="drugrecall.link"
          icon="link"
          label={t('drugrecall.link')}
          value={link}
          handleChange={updateLink}
          //error={deviceIdError ? t('error.' + deviceIdError) : ''}
        />
        <Input
          name="drugrecall.on_behalf"
          icon="user"
          label={t('drugrecall.on_behalf')}
          value={on_behalf}
          handleChange={updateOnBehalf}
          //error={deviceIdError ? t('error.' + deviceIdError) : ''}
        />
        <Button
          label={t('drugrecall.save')}
          type="default"
          icon="arrow-right"
          loading={addMediUDIregexLoading}
          splitted
        />
      </form>
    </div>
  );

  return (
    <BoxMessage
      primary={{
        title: t('drugrecall.addbox.title'),
        text: t('drugrecall.addbox.text'),
        illustration: 'typing',
        hideIllustration: true,
        more: addMediUDIregexForm,
      }}
    />
  );
};
export default BoxAddDrugRecall;
