import ButtonIcon from 'components/misc/button-icon';
import Tag from 'components/misc/tag';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import '../../theme/components/card-user.scss';

const CardUser = (props) => {
  const { t } = useTranslation('__global');
  let profile = props.profile;
  let link = '/users/edit/' + profile.id;
  const history = useHistory();

  function handleClick() {
    history.push(link);
  }

  let address = '';
  if (profile.street || profile.street_no || profile.zip || profile.place) {
    var streetAndNo = [profile.street || '', profile.street_no || ''].join(' ');
    var zipAndPlace = [profile.zip || '', profile.place || ''].join(' ');
    streetAndNo = streetAndNo.length === 1 ? false : streetAndNo;
    zipAndPlace = zipAndPlace.length === 1 ? false : zipAndPlace;
    let a = [streetAndNo, zipAndPlace];
    address = a.filter(Boolean).join(', ');
  }

  return (
    <div
      className={
        'user-card card ' + (profile.active === 0 ? 'deactivated' : '')
      }
      onClick={handleClick}>
      <div className="v-stack col-s-1 col-e-5">
        <strong>{profile.email}</strong>
        <small>{profile.last_activity}</small>
      </div>
      <div className="v-stack col-s-5 col-e-9">
        <strong>
          {profile.forename} {profile.lastname}
        </strong>
        <span className="small">{address}</span>
      </div>
      <div className="flex-end align-center col-s-9 col-e-13 flex-space">
        <Tag value={t(profile.role)} /> <ButtonIcon icon="edit-2" />
      </div>
    </div>
  );
};

export default CardUser;
