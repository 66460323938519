import ButtonIcon from 'components/misc/button-icon';
import Tag from 'components/misc/tag';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import '../../theme/components/card-device.scss';

const CardDevice = ({ data }) => {
  const { t } = useTranslation('__global');
  const history = useHistory();

  var status = t('devices.status.undefined');
  if (data.verified === 0) {
    status = t('devices.status.unverified');
  } else if (data.verified === 1) {
    status = t('devices.status.verified');
  }

  let deviceImage = require('../../assets/devices/device-unknown.png');
  let imgAlt = t('devices.status.unknown_device');
  switch (data.type) {
    case 'SmartPilotRefAppDevType':
      deviceImage = require('../../assets/devices/device-smartpilot.png');
      imgAlt = t('devices.types.' + data.type);
      break;
    case 'SmartPilot_D2C':
      deviceImage = require('../../assets/devices/device-smartpilot-d2c.png');
      imgAlt = t('devices.types.' + data.type);
      break;
    case 'YpsoMateReusable':
      deviceImage = require('../../assets/devices/device-ypsomate-reusable.png');
      imgAlt = t('devices.types.' + data.type);
      break;
    default:
      break;
  }

  const detailClick = (event) => {
    event.stopPropagation();
    return history.push('/devices/' + data.id);
  };

  return (
    <div
      className="card device-card"
      onClick={detailClick ? detailClick : undefined}>
      <div className="col-s-1 col-e-8 flex">
        <img className="device-image" src={deviceImage.default} alt={imgAlt} />
        <div className="v-stack device-data">
          <h3>{t('devices.types.' + data.type)}</h3>
          <small>{data.external_device_id}</small>
        </div>
      </div>
      <div className="flex-end align-center col-s-10 col-e-13 flex-space">
        <Tag value={status} />
        <ButtonIcon icon="arrow-right" />
      </div>
    </div>
  );
};

export default CardDevice;
