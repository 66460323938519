import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icons from 'components/misc/icons';

const NavigationAdmin = () => {
  let location = useLocation();
  const { t } = useTranslation('__global');

  return (
    <nav>
      <Link
        to="/"
        id="sidebar-dashboard"
        className={`${location.pathname === '/' ? 'active' : ''}`}>
        <Icons show="home" />{' '}
        <span className="text">{t('base.dashboard')}</span>
      </Link>
      <Link
        to="/users"
        className={`${location.pathname === '/users' ? 'active' : ''}`}>
        <Icons show="users" />
        <span className="text">{t('base.users')}</span>
      </Link>
      <hr />
    </nav>
  );
};
export default NavigationAdmin;
