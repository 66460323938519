import axios from 'axios';
import Input from 'components/forms/input';
import Button from 'components/misc/button';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getAxiosConfig } from 'utils/index';

const PatientCreateDiagnosis = ({ patient, afterSubmission = () => {} }) => {
  const [diagnosis, setDiagnosis] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation('__global');
  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    let data = {
      patient: patient,
      title: diagnosis,
    };
    axios
      .post(
        process.env.REACT_APP_API_URL + 'diagnosis/create',
        data,
        getAxiosConfig(),
      )
      .then((response) => {
        if (response.data.status === 'SUCCESS') {
          toast.success(t('patients.diagnosis_added'));
          afterSubmission();
        } else {
          toast.warn(t('patients.diagnosis_add_error'));
        }
        setIsLoading(false);
      });
  };
  return (
    <form className="grid" onSubmit={handleSubmit}>
      <h3 className="col-s-1 col-e-13">{t('patients.diagnosis_create')}</h3>
      <div className="col-s-1 col-e-13">
        <Input
          label={t('patients.diagnosis')}
          value={diagnosis}
          name="new-diagnosis"
          handleChange={(e) => {
            setDiagnosis(e.target.value);
          }}
        />
      </div>

      <div className="col-s-7 col-e-13 flex-end">
        <Button
          label={t('base.create')}
          type="prominent"
          icon="arrow-right"
          loading={isLoading}
        />
      </div>
    </form>
  );
};
export default PatientCreateDiagnosis;
