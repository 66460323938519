import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from './select';

const SelectDeviceType = ({ handleChange = () => {}, error = '' }) => {
  const { t } = useTranslation('__global');

  const deviceTypes = [
    { value: 'none', label: t('deviceSelection.choose') },
    { value: 'YpsoMateOn', label: t('deviceSelection.ymon') },
    { value: 'YpsoMateReusable', label: t('deviceSelection.ymre') },
  ];

  return (
    <Select
      label={t('deviceSelection.label')}
      name="device"
      values={deviceTypes}
      handleChange={handleChange}
      error={error}
    />
  );
};
export default SelectDeviceType;
