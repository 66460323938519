import PanelTop from 'components/layout/panel-top';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../theme/views/services.scss';
import ServiceDetail from '../../components/misc/service-detail';
import axios from 'axios';
import moment from 'moment';
import Modal from '../../components/misc/modal';
import Input from '../../components/forms/input';
import Select from '../../components/forms/select';
import Button from '../../components/misc/button';
import { getAxiosConfig } from '../../utils';
import { toast } from 'react-toastify';
import ConfirmationModal from '../../components/forms/confirmation-modal';

const ACTION = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
};

const CONNECTION_TYPES = [
  {
    label: 'HTTPS',
    value: 'HTTPS',
  },
  {
    label: 'COAP',
    value: 'COAP',
  },
];

const Services = () => {
  const { t: tGlobal } = useTranslation('__global');
  const { t } = useTranslation('__ypsomed');
  const [services, setServices] = useState([]);
  const [averagePing, setAveragePing] = useState(0);
  const [action, setAction] = useState({ action: null, data: null });
  const [selectedService, setSelectedService] = useState({});

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const token = localStorage.getItem('caladrius_jwt');
        const response = await axios.get(
          `${process.env.REACT_APP_MONITORING_API_URL}/services`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        setServices(response.data);

        if (response.data.length === 0) return;

        const averagePingResponse = await axios.get(
          `${
            process.env.REACT_APP_MONITORING_API_URL
          }/responses/average?service_ids=${response.data
            .map((r) => r._id)
            .join(',')}&start=${moment().startOf('day').unix()}&end=${moment()
            .endOf('day')
            .unix()}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        setAveragePing(averagePingResponse.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchServices();
  }, []);

  useEffect(() => {
    switch (action.action) {
      case ACTION.CREATE:
        setSelectedService({ type: action.data.type });
        break;
      case ACTION.UPDATE:
        setSelectedService(action.data);
        break;
    }
  }, [action]);

  const createService = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_MONITORING_API_URL}/services`,
        selectedService,
        getAxiosConfig(),
      );

      setServices([...services, response.data]);
    } catch (error) {
      toast.error(error.response.data.description);
      return;
    }

    toast.success(t('services.status_messages.created'));
    setAction({ action: null, data: null });
  };

  const updateService = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_MONITORING_API_URL}/services/${selectedService._id}`,
        selectedService,
        getAxiosConfig(),
      );

      setServices([
        ...services.filter((s) => s._id !== selectedService._id),
        response.data,
      ]);
    } catch (error) {
      toast.error(error.response.data.description);
      return;
    }

    toast.success(t('services.status_messages.updated', selectedService.name));
    setAction({ action: null, data: null });
  };

  const deleteService = async (e) => {
    e.preventDefault();

    try {
      await axios.delete(
        `${process.env.REACT_APP_MONITORING_API_URL}/services/${action.data._id}`,
        getAxiosConfig(),
      );

      setServices(services.filter((s) => s._id !== action.data._id));
    } catch (error) {
      toast.error(error.response.data.description);
      return;
    }

    toast.success(t('services.status_messages.deleted', action.data.name));
    setAction({ action: null, data: null });
  };

  const onChange = (e) => {
    setSelectedService({ ...selectedService, [e.target.name]: e.target.value });
  };

  const getModal = (action) => {
    switch (action.action) {
      case ACTION.CREATE:
        return (
          <Modal
            show={true}
            onHide={() => setAction({ action: null, data: null })}
            content={
              <div className={'modal-content'}>
                <h3>{t('services.add_service')}</h3>
                <form onSubmit={createService}>
                  <Input
                    label={t('services.name')}
                    name={'name'}
                    infield={false}
                    value={selectedService.name}
                    handleChange={onChange}
                  />
                  <Input
                    label={t('services.abbreviation')}
                    name={'abbreviation'}
                    infield={false}
                    value={selectedService.abbreviation}
                    handleChange={onChange}
                  />
                  <Input
                    label={t('services.technology')}
                    name={'technology'}
                    infield={false}
                    value={selectedService.technology}
                    handleChange={onChange}
                  />
                  <Select
                    label={t('services.type')}
                    name={'type'}
                    values={CONNECTION_TYPES}
                    handleChange={onChange}
                  />
                  <Input
                    label={'URL'}
                    name={'url'}
                    infield={false}
                    value={selectedService.url}
                    handleChange={onChange}
                  />
                  <Button
                    label={t('services.create')}
                    type="prominent"
                    icon="arrow-right"
                    isLoading={false}
                  />
                </form>
              </div>
            }
          />
        );
      case ACTION.UPDATE:
        return (
          <Modal
            show={true}
            onHide={() => setAction({ action: null, data: null })}
            content={
              <div className={'modal-content'}>
                <h3>{t('services.edit_service')}</h3>
                <form onSubmit={updateService}>
                  <Input
                    label={t('services.name')}
                    name={'name'}
                    infield={false}
                    value={selectedService && selectedService.name}
                    handleChange={onChange}
                  />
                  <Input
                    label={t('services.abbreviation')}
                    name={'abbreviation'}
                    infield={false}
                    value={selectedService && selectedService.abbreviation}
                    handleChange={onChange}
                  />
                  <Input
                    label={t('services.technology')}
                    name={'technology'}
                    infield={false}
                    value={selectedService && selectedService.technology}
                    handleChange={onChange}
                  />
                  <Select
                    label={t('services.type')}
                    name={'type'}
                    values={CONNECTION_TYPES}
                    handleChange={onChange}
                    startingValue={action.data.type}
                  />
                  <Input
                    label={'URL'}
                    name={'url'}
                    infield={false}
                    value={selectedService && selectedService.url}
                    handleChange={onChange}
                  />
                  <Button
                    label={t('services.create')}
                    type="prominent"
                    icon="arrow-right"
                    isLoading={false}
                  />
                </form>
              </div>
            }
          />
        );
    }
  };

  return (
    <div className="view services">
      <ConfirmationModal
        title={t('services.delete_confirmation')}
        isShown={action.action === ACTION.DELETE}
        onAccept={(e) => {
          deleteService(e);
          setAction({ action: null, data: null });
        }}
        onDecline={() => {
          setAction({ action: null, data: null });
        }}
      />
      <PanelTop
        left={<h1>{`${tGlobal('base.services')}`}</h1>}
        right={
          <Button
            label={t('services.add_service')}
            icon={'plus'}
            action={() =>
              setAction({ action: ACTION.CREATE, data: { type: 'HTTPS' } })
            }
          />
        }
      />
      {getModal(action)}
      <div className="service-container">
        {services.map((service) => {
          return (
            <ServiceDetail
              key={service._id}
              service={service}
              averageResponseTime={averagePing[service._id]}
              onEditPressed={(service) => {
                setAction({ action: ACTION.UPDATE, data: service });
              }}
              onDeletePressed={(service) => {
                setAction({ action: ACTION.DELETE, data: service });
              }}
            />
          );
        })}
      </div>
    </div>
  );
};
export default Services;
