import axios from 'axios';
import CardInjectionTimeline from 'components/cards/injection-timeline';
import MessageInline from 'components/misc/message-inline';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { getAxiosConfig, useProfile } from 'utils/index';

import '../../theme/components/therapy-timeline.scss';

const TherapyTimeline = () => {
  const [therapy, setInjections] = useState([]);
  const profile = useProfile();
  const { t } = useTranslation('__global');

  const loadTherapy = async () => {
    if (!profile.loading) {
      const result = await axios.get(
        process.env.REACT_APP_API_URL +
          '/event/get/therapy/' +
          profile.data.id +
          '?limit=5&order=desc&start=today',
        getAxiosConfig(),
      );
      setInjections(result.data.events);
    }
  };

  useEffect(() => {
    loadTherapy();
    const therapyUpdateInterval = setInterval(() => {
      loadTherapy();
    }, 2000);
    return () => {
      clearInterval(therapyUpdateInterval);
    };
  }, [profile]);

  return (
    <div className="therapy-timeline">
      <h3>{t('therapy.timeline_title')}</h3>
      <div className="timeline">
        {therapy.length === 0 ? (
          <MessageInline
            type="discreet"
            message={t('dashboards.patient.no_injections')}
          />
        ) : (
          <TransitionGroup className="injections flex">
            {therapy.map((injection) => (
              <CSSTransition key={injection.id} timeout={600} classNames="item">
                <CardInjectionTimeline injection={injection} />
              </CSSTransition>
            ))}
          </TransitionGroup>
        )}
      </div>
    </div>
  );
};

export default TherapyTimeline;
