import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { toast } from 'react-toastify';
import { formatDate, getAxiosConfig, padNum, useProfile } from 'utils/index';
import PanelTop from 'components/layout/panel-top';
import Input from 'components/forms/input';
import ButtonLink from 'components/misc/button-link';
import LoadingIndicator from 'components/loaders/indicator';
import CardDrugRecall from 'components/cards/drug-recall';
import BoxAddDrugRecall from 'components/organisms/box-drug-recall';
import TableRow from 'components/table/table-row';
import Table from 'components/table/table';

var filterTimeoutID = null;

const Drugrecall = () => {
  const { t } = useTranslation('__global');
  const profile = useProfile();
  const [drugRecallList, setDrugRecall] = useState([]);
  const [drugRecallLoading, setDrugRecallLoading] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [medicheckList, setMedicheckList] = useState([]);
  const [filterValue, setFilterValue] = useState('');

  const fetchData = async () => {
    const result = await axios.get(
      process.env.REACT_APP_API_URL + 'drug_recall/get',
      getAxiosConfig(),
    );
    if (result.data.length > 0) {
      setDrugRecall(result.data);
    }
    setDrugRecallLoading(false);
  };

  const fetchMedicheckData = async () => {
    const params = {
      start: 0,
      limit: 100,
      search: filterValue,
    };
    const config = getAxiosConfig();
    const medicheckresult = await axios.request({
      method: 'GET',
      url: process.env.REACT_APP_API_URL + 'medicheck',
      params: params,
      headers: config.headers,
    });
    setMedicheckList(medicheckresult.data.list);
    if (medicheckresult.data.list.length === 0) {
      console.log('no data');
    }
    setDrugRecallLoading(false);
  };

  const updateFilterText = (event) => {
    var tmp = event.target.value;
    setFilterValue(tmp);
  };

  useEffect(() => {
    clearTimeout(filterTimeoutID);
    filterTimeoutID = setTimeout(fetchMedicheckData, 1000);
    setDrugRecallLoading(true);
  }, [filterValue]);

  useEffect(() => {
    setDrugRecallLoading(true);
    setTabIndex(0);
    fetchData();
    fetchMedicheckData();
  }, []);

  const changeTab = (index) => {
    setTabIndex(index);
  };

  const onAdded = () => {
    fetchData();
  };
  const onDelete = (event) => {
    const id = event.target.getAttribute('data-index');
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}drug_recall/delete/${id}`,
        getAxiosConfig(),
      )
      .then(() => {
        toast.success(t('drugrecall.addbox.deleted'));
        fetchData();
      });
  };

  return (
    <div className="view">
      {tabIndex == 0 ? (
        <PanelTop
          loading={profile.loading}
          left={<h1>{t('base.drug_recall')}</h1>}
        />
      ) : (
        <PanelTop
          loading={profile.loading}
          left={<h1>{t('base.drug_recall')}</h1>}
          right={
            <Input
              label={t('drugrecall.filter')}
              value={filterValue}
              handleChange={updateFilterText}
              icon="search"
            />
          }
        />
      )}

      <div className="panel-top-tabs subnavigation">
        <ButtonLink
          className={tabIndex == 0 ? 'active' : ''}
          label={t('drugrecall.tab_1')}
          action={() => {
            changeTab(0);
          }}
        />

        <ButtonLink
          className={tabIndex == 1 ? 'active' : ''}
          label={t('drugrecall.tab_2')}
          action={() => {
            changeTab(1);
          }}
        />
      </div>
      {tabIndex == 0 ? (
        <div className="content grid">
          {drugRecallLoading ? (
            <div>
              <LoadingIndicator spaced={true} />
            </div>
          ) : drugRecallList.length !== 0 ? (
            <div className="col-s-1 col-e-8 table-container">
              <table className="w100p line-bottom default">
                <thead>
                  <tr>
                    <th>{t('drugrecall.table.regex')}</th>
                    <th>{t('drugrecall.table.description')}</th>
                    <th>{t('drugrecall.table.user')}</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {drugRecallList.map((drug) => (
                    <CardDrugRecall
                      key={drug.id}
                      data={drug}
                      onDelete={onDelete}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            ''
          )}
          <div className="col-s-8 col-e-13">
            <BoxAddDrugRecall onAdded={onAdded} />
          </div>
        </div>
      ) : (
        <div className="extra-padding-lr">
          <div></div>

          <Table
            head={
              <TableRow
                type="th"
                data={[
                  t('drugrecall.table.id'),
                  t('drugrecall.table.date'),
                  t('drugrecall.table.udi'),
                  t('drugrecall.table.device'),
                  t('drugrecall.table.result'),
                  t('drugrecall.table.text'),
                  t('drugrecall.table.detail'),
                  t('drugrecall.table.regex'),
                ]}
              />
            }
            body={medicheckList.map((row) => (
              <TableRow
                key={row.id}
                data={[
                  padNum(row.id, 0, 4),
                  formatDate(row.date),
                  row.MediUDI,
                  row.external_device_id,
                  row.result,
                  row.text,
                  {
                    value: row.detail
                      ? row.detail.substring(0, 30) +
                        (row.detail.length > 29 ? '...' : '')
                      : t('base.no_data'),
                    tooltip: row.detail,
                  },
                  row.mediUDIregex,
                ]}
              />
            ))}
          />
          {drugRecallLoading ? (
            <div>
              <LoadingIndicator spaced={true} />
            </div>
          ) : (
            ''
          )}
        </div>
      )}
    </div>
  );
};
export default Drugrecall;
